import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { Divider, IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import DialogComponent from '../../components/DialogComponent/DialogComponent';
import ReactSelectMaterial from '../../components/ReactSelectMaterial/ReactSelectMaterial';
import API from '../../constants/api';
import {
    saveQueueData,
    toggleQueueSelect,
} from '../../redux/modules/queue/queue-actions';
import {
    apiCatchBlockFunction, getQueueAuthHeaderConfigForRequest, isArrayValidAndNotEmpty, isValidText,
} from '../../constants/CommonUtil';
import { getSelectedQueue, getServiceLocation, getServiceLocationStatus } from '../../constants/state';
import { hideSpinner, showSpinner } from '../../redux/modules/spinner/spinner';
import {
    GLOBAL_PROPERTIES,
    QUEUE_LOCATION_STATUS,
    registrationV2,
    registrationV3,
} from '../../constants/constants';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';
import ActionButton from '../../components/ActionButton/ActionButton';
import { errorMessage } from '../../redux/modules/message/message-actions';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import ValidateUserDialog from '../ValidateUserDialog/ValidateUserDialog';

class QueueSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            queue: {},
            serviceLocation: {},
            status: '',
            serviceLocations: [],
            editable: false,
            showValidateUserDialog: false,
        };
    }

    componentDidMount() {
        this.getStoredQueueData();
    }

    checkUserHasPrivilegesToEdit = (queue, serviceLocation) => {
        const {
            updateQueuePrivilege,
        } = this.props;
        if (isObjectValidAndNotEmpty(queue) && isObjectValidAndNotEmpty(serviceLocation)) {
            if (isArrayValidAndNotEmpty(updateQueuePrivilege)) {
                if (checkIfPrivilegeExistsForUser(updateQueuePrivilege)) {
                    this.setState({ editable: true });
                }
            }
        } else {
            this.setState({ editable: true });
        }
    };

    getStoredQueueData = () => {
        const queue = getSelectedQueue();
        const serviceLocation = getServiceLocation();
        const serviceLocationStatus = getServiceLocationStatus();

        this.setState({
            queue,
            serviceLocation,
            status: serviceLocationStatus,
        }, this.checkUserHasPrivilegesToEdit(queue, serviceLocation));
        if (queue) {
            this.loadServiceLocation(queue);
        }
        console.log(queue);
    };

    onClose = () => {
        this.props.dispatch(toggleQueueSelect(true));
    };

    handleSave = async () => {
        const {
            queue,
            serviceLocation,
            status,
        } = this.state;
        const {
            dispatch,
            queueAuth,
            locationPath,
        } = this.props;
        const showStatus = (locationPath.includes(registrationV2) || locationPath.includes(registrationV3));

        if (!isObjectValidAndNotEmpty(queue)) {
            dispatch(errorMessage('Must select a queue'));
            return;
        }
        if (!isObjectValidAndNotEmpty(serviceLocation)) {
            dispatch(errorMessage('Must select a location'));
            return;
        }
        if (showStatus && !isValidText(status)) {
            dispatch(errorMessage('Must select a status'));
            return;
        }
        dispatch(showSpinner());
        try {
            if (showStatus) {
                const serviceLocationId = getStringFromObject('key', serviceLocation);
                const headers = getQueueAuthHeaderConfigForRequest(queueAuth);
                await axios.put(API.QUEUE.CHANGE_STATUS, {}, {
                    headers,
                    params:
                        { queueLocationAssignmentId: serviceLocationId, status },
                });
            }
            dispatch(saveQueueData(
                queue,
                serviceLocation,
                status,
            ));
            this.onClose();
        } catch (error) {
            apiCatchBlockFunction(error, dispatch);
        } finally {
            dispatch(hideSpinner());
        }
    };

    onSelectQueue = (queue) => {
        if (isObjectValidAndNotEmpty(queue)) {
            this.setState({ queue });
            this.loadServiceLocation(queue);
        } else {
            this.setState({ queue: null });
        }
        this.onSelectServiceLoc({});
    };

    onSelectServiceLoc = (serviceLocation) => {
        this.setState({ serviceLocation });
        this.handleStatusChange(getStringFromObject('status', serviceLocation))();
    };

    handleStatusChange = (status) => () => {
        this.setState({ status });
    };

    loadServiceLocation = async (queue) => {
        const { dispatch, queueAuth } = this.props;
        try {
            dispatch(showSpinner());
            const headers = getQueueAuthHeaderConfigForRequest(queueAuth);
            const resp = await axios.get(`${API.QUEUE.FIND_ONE}${queue.key}`, { headers });
            const serviceLocations = getStringFromObject('data.queueLocationDtos', resp, [])
                .map((e) => ({ key: e.id, value: getStringFromObject('provider.value', e), status: e.status }));
            this.setState({
                serviceLocations,
            });
        } catch (e) {
            apiCatchBlockFunction(e, this.props.dispatch);
        } finally {
            dispatch(hideSpinner());
        }
    };

    validateUserFunction = async (loginCredentials) => {
        const {
            updateQueuePrivilege,
            dispatch,
        } = this.props;
        try {
            const response = await axios.post(API.LOGIN.VALIDATE_USER_AND_GET_PRIVILEGES, loginCredentials);
            const privilegesOfUser = response.data;

            if (isArrayValidAndNotEmpty(privilegesOfUser)) {
                for (let i = 0; i < updateQueuePrivilege.length; i += 1) {
                    const aPrivilege = updateQueuePrivilege[i];
                    if (!privilegesOfUser.includes(aPrivilege)) {
                        dispatch(errorMessage('User does not have sufficient privilege to perform this operation'));
                        return;
                    }
                }
                this.toggleValidateUser();
                this.setState({ editable: true });
            } else {
                dispatch(errorMessage('Username or password does not match'));
            }
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    makeFieldsEditableForPrivilegedUser = () => {
        const {
            updateQueuePrivilege,
            dispatch,
        } = this.props;
        if (isArrayValidAndNotEmpty(updateQueuePrivilege)) {
            this.toggleValidateUser();
        } else {
            dispatch(errorMessage('Privilege to change queue is not configured'));
        }
    };

    toggleValidateUser = () => {
        this.setState((prevState) => ({
            showValidateUserDialog: !prevState.showValidateUserDialog,
        }));
    };

    render() {
        const {
            queueData,
            enableApprovalQueue,
            enableRegistrationQueue,
            locationPath,
        } = this.props;
        const {
            queue,
            serviceLocation,
            status,
            editable,
            showValidateUserDialog,
        } = this.state;

        const showStatus = (locationPath.includes(registrationV2) || locationPath.includes(registrationV3));
        const { serviceLocations } = this.state;
        const dialogOpen = getStringFromObject('openSelect', queueData);
        return (
            <DialogComponent
                header="Select Your Queue"
                open={dialogOpen}
                handleClose={this.onClose}
                dialogActions={(
                    <ActionButton onClick={this.handleSave} className="ml-1" testId="Save">
                        Save
                    </ActionButton>
                )}
            >
                {
                    showStatus
                    && (
                        <Grid container justify="flex-end">
                            {
                                Object.keys(QUEUE_LOCATION_STATUS).map((aKey) => (
                                    <Grid item key={aKey}>
                                        <ActionButton
                                            onClick={this.handleStatusChange(aKey)}
                                            primary={status === aKey}
                                        >
                                            {aKey}
                                        </ActionButton>
                                    </Grid>
                                ))
                            }

                        </Grid>
                    )
                }
                <Divider className="mt-1" />
                {
                    !editable
                    && (
                        <Grid container justify="flex-end">
                            <Grid item>
                                <IconButton
                                    onClick={this.makeFieldsEditableForPrivilegedUser}
                                >
                                    <Edit />
                                </IconButton>
                            </Grid>
                        </Grid>
                    )
                }
                {
                    (enableRegistrationQueue || enableApprovalQueue)
                    && (
                        <React.Fragment>
                            <Grid container className={editable ? 'mt-1' : ''} justify="space-around">
                                <Grid item sm>
                                    <ReactSelectMaterial
                                        autocomplete
                                        label="Queue"
                                        testId="queue"
                                        dataSourceApi={API.GENERAL_QUEUE.SEARCH_QUEUE}
                                        dataSourceConfig={{
                                            text: 'value',
                                            value: 'key',
                                        }}
                                        isDisabled={!editable}
                                        value={queue}
                                        onChange={this.onSelectQueue}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className="mt-1" justify="space-around">
                                <ReactSelectMaterial
                                    options={serviceLocations}
                                    dataSourceConfig={{
                                        value: 'key',
                                        text: 'value',
                                    }}
                                    onChange={this.onSelectServiceLoc}
                                    label="Service location"
                                    value={serviceLocation}
                                    isDisabled={!editable}
                                    testId="service-location"
                                />
                            </Grid>
                        </React.Fragment>
                    )
                }
                {
                    showValidateUserDialog
                    && (
                        <ValidateUserDialog
                            dispatch={this.props.dispatch}
                            dialogTitle="Enter Supervisor's Username And Password"
                            onClose={this.toggleValidateUser}
                            validateFunction={this.validateUserFunction}
                        />
                    )
                }
            </DialogComponent>
        );
    }
}

QueueSelect.propTypes = {
    dispatch: PropTypes.func.isRequired,
    queueData: PropTypes.object,
    enableApprovalQueue: PropTypes.bool,
    enableRegistrationQueue: PropTypes.bool,
    queueAuth: PropTypes.string,
    locationPath: PropTypes.string,
    updateQueuePrivilege: PropTypes.array,
};

QueueSelect.defaultProps = {
    queueData: null,
    enableRegistrationQueue: false,
    enableApprovalQueue: false,
    queueAuth: '',
    locationPath: '',
    updateQueuePrivilege: [],
};

const mapStateToProps = (state) => ({
    queueData: state.queue,
    enableApprovalQueue: getStringFromObject(`appConfiguration.${GLOBAL_PROPERTIES.QUEUE_APPROVAL_ENABLED.replace(/\./g, '_')}`, state, false),
    enableRegistrationQueue: getStringFromObject(`appConfiguration.${GLOBAL_PROPERTIES.QUEUE_REGISTRATION_ENABLED.replace(/\./g, '_')}`, state, false),
    queueAuth: getStringFromObject('appConfiguration.queueAuth', state),
    updateQueuePrivilege: getStringFromObject('appConfiguration.updateQueuePrivilege', state) || ['pos.cash.edit'],
});

export default connect(mapStateToProps)(QueueSelect);
