import {
    COMBINEMODE,
    generateObjectWithCombineMode,
    generateObjectWithCriteriaMode,
    getCombined,
} from '../../../sagas/util';
import api from '../../../constants/api';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';
import {
    getStartAndEndDateFromOrderTypeDateFilter,
    isArrayValidAndNotEmpty,
    isStringNullOrUndefined,
    ORDER_TYPE_DATE_FILTERS,
} from '../../../constants/CommonUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { multiply, add } from '../../../constants/PrecisionUtil';
import { getEndOfDay, getStartOfDay } from '../../../constants/DateUtil';

export const invoiceStates = {
    PAID: {
        value: 'paid',
        label: 'Paid',
    },
    OPEN: {
        value: 'open',
        label: 'Open',
    },
};

export const payerTypes = {
    COMPANY: {
        value: 'CREDIT',
        label: 'Company',
    },
    INSURANCE: {
        value: 'INSURANCE',
        label: 'Insurance',
    },
    CORPORATE: {
        value: 'CORPORATE',
        label: 'Corporate',
    },
};

export const bundleInvoiceTableFilter = () => ([
    {
        name: 'datePeriod',
        type: 'dateRange',
        label: 'Period',
        showOnTop: true,
        grid: '3',
        valueField: 'key',
        disableClear: true,
        options: Object.keys(ORDER_TYPE_DATE_FILTERS).map((aPeriod) => ({
            key: aPeriod,
            value: ORDER_TYPE_DATE_FILTERS[aPeriod],
        })),
        defaultOption: {
            key: 'LAST_30_DAYS',
            value: 'Past 30 Days',
        },
    },
    {
        name: 'payer',
        type: 'autocomplete',
        label: 'Payer',
        valueField: 'id',
        disableClear: true,
        dataSourceConfig: {
            text: 'name',
            value: 'id',
        },
        dataSourceApi: `${api.SEARCH.RES_PARTNER_BASE}&isPayor=true&searchString=`,
    },
    {
        name: 'state',
        type: 'select',
        label: 'State',
        valueField: 'key',
        disableClear: true,
        options: [
            invoiceStates.OPEN,
            invoiceStates.PAID,
        ].map((aPayerType) => ({
            key: aPayerType.value,
            value: aPayerType.label,
        })),
    },
]);

export const createSearchConstructForBundleInvoice = (filters) => {
    let searchConstruct = null;
    const searchText = getStringFromObject('searchText', filters);
    const payerId = getStringFromObject('payer.id', filters);
    const state = getStringFromObject('state.key', filters);

    searchConstruct = generateObjectWithCriteriaMode('account_invoice.type', 'bundle_invoice', '=');

    if (!isStringNullOrUndefined(payerId)) {
        searchConstruct = getCombined(
            searchConstruct,
            COMBINEMODE.AND,
            generateObjectWithCriteriaMode('res_partner.id', payerId, '='),
        );
    }

    if (!isStringNullOrUndefined(state)) {
        searchConstruct = getCombined(
            searchConstruct,
            COMBINEMODE.AND,
            generateObjectWithCriteriaMode('account_invoice.state', state, '='),
        );
    }

    if (searchText && searchText.trim()) {
        searchConstruct = getCombined(
            searchConstruct,
            COMBINEMODE.AND,
            generateObjectWithCombineMode(
                generateObjectWithCriteriaMode('account_invoice.name', searchText, ':'),
                COMBINEMODE.OR,
                generateObjectWithCriteriaMode('account_invoice.number', searchText, ':'),
            ),
        );
    }

    const datePeriod = getStringFromObject('datePeriod', filters)
        || getStartAndEndDateFromOrderTypeDateFilter(ORDER_TYPE_DATE_FILTERS.LAST_30_DAYS);

    if (isObjectValidAndNotEmpty(datePeriod)) {
        const {
            startDate,
            endDate,
        } = datePeriod;
        if (startDate && endDate) {
            searchConstruct = getCombined(
                searchConstruct,
                COMBINEMODE.AND,
                getCombined(
                    generateObjectWithCriteriaMode(
                        'account_invoice.dateInvoice',
                        getStartOfDay(startDate).getTime(),
                        '>=',
                    ),
                    COMBINEMODE.AND,
                    generateObjectWithCriteriaMode(
                        'account_invoice.dateInvoice',
                        getEndOfDay(endDate).getTime(),
                        '<=',
                    ),
                ),
            );
        } else if (startDate) {
            searchConstruct = getCombined(
                searchConstruct,
                COMBINEMODE.AND,
                generateObjectWithCriteriaMode('account_invoice.dateInvoice', getStartOfDay(startDate).getTime(), '>='),
            );
        } else if (endDate) {
            searchConstruct = getCombined(
                searchConstruct,
                'AND',
                generateObjectWithCriteriaMode('account_invoice.dateInvoice', getEndOfDay(endDate).getTime(), '<='),
            );
        }
    }

    return searchConstruct;
};

export const getUiObject = (bundleInvoice) => {
    if (isObjectValidAndNotEmpty(bundleInvoice)) {
        const uiObject = {
            uuid: bundleInvoice.uuid,
            payerName: getStringFromObject('payer.value', bundleInvoice),
            totalAmount: bundleInvoice.amountTotal,
            invoiceNumber: bundleInvoice.number,
        };
        const lines = getStringFromObject('accountInvoiceLineDtoList', bundleInvoice, []);
        if (isArrayValidAndNotEmpty(lines)) {
            uiObject.bundleInvoiceLines = lines.map((l) => {
                const line = { ...l };
                line.uuid = l.uuid;
                const taxPercent = getStringFromObject('amount', l.taxAccountDetails[0]);
                line.taxType = getStringFromObject('name', l.taxAccountDetails[0]);
                line.grossAmount = l.priceUnit;
                line.discount = l.discountNegotiated;
                line.totalDeductibleAmount = l.totalCopayAmount || 0;
                line.netAmount = l.priceSubtotal;
                line.taxAmount = multiply(l.priceSubtotal, taxPercent);
                line.totalAmount = add(l.priceSubtotal, line.taxAmount);
                return line;
            });
        }
        return uiObject;
    }
    return bundleInvoice;
};
