import { takeLatest } from 'redux-saga/effects';
import {
    FETCH_BUNDLE_INVOICE_LIST_REQUEST,
    FETCH_BUNDLE_INVOICE_REQUEST,
} from '../../redux/modules/bundleInvoice/bundleInvoice-actions';
import { fetchBundleInvoiceListWorkerSaga, fetchBundleInvoiceWorker } from './bundleInvoiceWorkerSaga';

export function* fetchBundleInvoice() {
    yield takeLatest(FETCH_BUNDLE_INVOICE_LIST_REQUEST, fetchBundleInvoiceListWorkerSaga);
}

export function* fetchBundleInvoiceById() {
    yield takeLatest(FETCH_BUNDLE_INVOICE_REQUEST, fetchBundleInvoiceWorker);
}
