import API from '../../constants/api';

export const JOURNAL_DATE_FILTERS = {
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    LAST_7_DAYS: 'Past 7 Days',
    LAST_10_DAYS: 'Past 10 Days',
    LAST_30_DAYS: 'Past 30 Days',
    LAST_60_DAYS: 'Past 60 Days',
    LAST_90_DAYS: 'Past 90 Days',
    LAST_180_DAYS: 'Past 180 Days',
};

export const JOURNAL_ENTRY_FILTER_SCHEMA = openingJournal => ([
    {
        name: 'journal',
        label: 'Journal',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi: API.SEARCH.ACCOUNT_JOURNAL,
        dataSourceConfig: {
            text: 'name',
            value: 'id',
        },
        disabled: !!openingJournal,
        disableClear: !!openingJournal,
    },
    {
        name: 'memo',
        label: 'Memo',
        type: 'fieldWithOperation',
        options: ['contains'],
        valueType: 'text',
        valueLabel: 'Search With',
    },
    {
        name: 'draftEntries',
        label: 'Include Draft Entries',
        type: 'checkbox',
        defaultValue: true,
    },
    {
        name: 'datePeriod',
        type: 'dateRange',
        label: 'Period',
        valueField: 'key',
        disableClear: true,
        showOnTop: true,
        options: Object.keys(JOURNAL_DATE_FILTERS).map(aPeriod => ({
            key: aPeriod,
            value: JOURNAL_DATE_FILTERS[aPeriod],
        })),
        defaultOption: {
            key: 'LAST_7_DAYS',
            value: 'Past 7 Days',
        },
        grid: 3,
    },
]);
