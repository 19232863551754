/*
    eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events,
    jsx-a11y/no-noninteractive-element-interactions
*/
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';
import DoneAll from '@material-ui/icons/DoneAll';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import api from '../../../constants/api';
import { claimSearchFieldFilters, claimStates } from '../ClaimsList/ClaimsListUtil';
import { COMBINEMODE, generateObjectWithCriteriaMode, getCombined } from '../../../sagas/util';
import { generateSearchFilterConstruct } from '../../GenericFilterComponent/GenericFilterComponentUtil';
import { apiCatchBlockFunction } from '../../../constants/CommonUtil';
import { displayWarning, showConfirmationDialog } from '../../../redux/modules/warningDialog/warningDialog-actions';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import { successMessage } from '../../../redux/modules/message/message-actions';
import { insurancePayerTypes } from '../AddNewPayer/AddNewPayerUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';
import { NumberOf } from '../../../constants/numberUtils';


export const iconStyle = {
    height: '1.7rem',
    width: '1.7rem',
    cursor: 'pointer',
};

export const commonBundleDetails = [
    {
        name: 'bundleNumber',
        find: 'bundleNumber',
        label: 'Bundle Number',
        grid: {
            sm: 3,
            md: 3,
            lg: 3,
        },
    },
    {
        name: 'invoiceNumber',
        find: 'invoiceNumber',
        label: 'Invoice Number',
        grid: {
            sm: 3,
            md: 3,
            lg: 3,
        },
    },
    {
        name: 'tpa',
        find: 'tpaUuid.value',
        label: 'TPA',
        grid: {
            sm: 3,
            md: 3,
            lg: 3,
        },
    },
    {
        name: 'bundleState',
        find: 'bundleState',
        label: 'Status',
        grid: {
            sm: 3,
            md: 3,
            lg: 3,
        },
    },
    {
        name: 'amountTotal',
        find: 'amountTotal',
        type: 'price',
        label: 'Total Amount',
        grid: {
            sm: 3,
            md: 3,
            lg: 3,
        },
    },
    {
        name: 'createDate',
        find: 'createDate',
        type: 'date',
        label: 'Create Date',
        grid: {
            sm: 3,
            md: 3,
            lg: 3,
        },
    },
    {
        name: 'submittedDate',
        find: 'submittedDate',
        type: 'date',
        label: 'Submitted Date',
        grid: {
            sm: 3,
            md: 3,
            lg: 3,
        },
    },
    {
        name: 'period',
        find: 'period',
        type: 'text',
        label: 'Period',
        grid: {
            sm: 3,
            md: 3,
            lg: 3,
        },
    },
    {
        name: 'opticalSalesAmount',
        find: 'opticalSalesAmount',
        type: 'text',
        label: 'Optical Amount',
        grid: {
            sm: 3,
            md: 3,
            lg: 3,
        },
    },
    {
        name: 'pharmacySalesAmount',
        find: 'pharmacySalesAmount',
        type: 'text',
        label: 'Pharmacy Amount',
        grid: {
            sm: 3,
            md: 3,
            lg: 3,
        },
    },
    {
        name: 'invoicePayer',
        find: 'invoicePayer.value',
        type: 'text',
        label: 'Invoice Payer',
        grid: {
            sm: 3,
            md: 3,
            lg: 3,
        },
    },
];

export const bundleTableFilter = () => {
    console.log('asdsadu0s-a0dusa-d');
    return ([
        {
            name: 'bundle.startDate',
            type: 'date',
            label: 'From',
            startOfDay: true,
            disableClear: true,
            maxDateField: 'bundle.endDate',
            convertToGMT: true,
            searchConstructFields: {
                name: 'bundle.periodStartDate',
                filterOperation: '>=',
            },
        },
        {
            name: 'bundle.endDate',
            type: 'date',
            label: 'To',
            endOfDay: true,
            additionalFieldToClear: ['bundle.startDate'],
            minDateField: 'bundle.startDate',
            defaultValueField: 'bundle.startDate',
            convertToGMT: true,
            searchConstructFields: {
                name: 'bundle.periodStartDate',
                filterOperation: '<=',
            },
        },
        {
            name: 'insurance_payer.type',
            type: 'select',
            label: 'Payer Type',
            valueField: 'key',
            disableClear: false,
            options: [
                insurancePayerTypes.COMPANY,
                insurancePayerTypes.INSURANCE,
                insurancePayerTypes.CORPORATE,
            ].map(aPayerType => ({
                key: aPayerType.value,
                value: aPayerType.label,
            })),
            searchConstructFields: {
                name: 'insurance_payer.type',
                filterOperation: '=',
            },
        },
        {
            name: 'bundle.bundleState',
            type: 'select',
            label: 'State',
            valueField: 'key',
            disableClear: true,
            options: Object.keys(claimStates).map(aClaimState => ({
                key: aClaimState,
                value: aClaimState,
            })),
            searchConstructFields: {
                name: 'bundle.bundleState',
                filterOperation: '=',
            },
        },
        {
            name: 'insurance_payer.uuid',
            type: 'autocomplete',
            label: 'TPA',
            valueField: 'key',
            disableClear: true,
            multiple: true,
            dataSourceApi:
                // eslint-disable-next-line
                `${api.SEARCH.INSURANCE_PAYERS_BY_TYPE}?payerType=COMPANY,INSURANCE,CORPORATE&searchString=`,
            searchConstructFields: {
                name: 'insurance_payer.uuid',
                filterOperation: '=',
            },
        },
        {
            name: 'insurance_payer_sub_insurer.uuid',
            type: 'autocomplete',
            label: 'Sub Insurer',
            valueField: 'key',
            disableClear: true,
            dataSourceApi:
                // eslint-disable-next-line
                `${api.SEARCH.INSURANCE_PAYERS_BY_TYPE}?payerType=SUBINSURANCE&searchString=`,
            searchConstructFields: {
                name: 'insurance_payer_sub_insurer.uuid',
                filterOperation: '=',
            },
        },
        {
            name: 'invoice_payer.uuid',
            type: 'autocomplete',
            label: 'Invoice Payer',
            valueField: 'key',
            dataSourceApi:
                // eslint-disable-next-line
                `${api.SEARCH.INSURANCE_PAYERS_BY_TYPE}?payerType=SUBINSURANCE&searchString=`,
            searchConstructFields: {
                name: 'invoice_payer.uuid',
                filterOperation: '=',
            },
        },
        {
            name: 'bundle.voided',
            type: 'checkbox',
            label: 'Show only archived bundles',
            disableClear: true,
            defaultValue: false,
            searchConstructFields: {
                name: 'bundle.voided',
                filterOperation: '===',
            },
        },
    ]);
};


export const bundleSearchFieldFilters = [
    {
        name: 'bundle.bundleNo',
        filterOperation: ':',
    },
    {
        name: 'bundle.name',
        filterOperation: ':',
    },
];

export const PAYER_TYPES = [
    { key: 'INSURANCE', value: 'TPA' },
    { key: 'SUBINSURANCE', value: 'SubInsurer' },
    { key: 'POLICY', value: 'Policy' },
];
export const dataSourceConfig = {
    text: 'value',
    value: 'key',
};

export const getClaimSearchConstruct = (filters, bundleUuid) => getCombined(
    generateObjectWithCriteriaMode('bundle.uuid', bundleUuid, '='),
    COMBINEMODE.AND,
    generateSearchFilterConstruct(getStringFromObject('searchText', filters), claimSearchFieldFilters),
);

export const showErrorAndWarnings = (e, task, dispatcher) => {
    e.stopPropagation();
    const errors = getStringFromObject('failureReason', task);
    const warnings = getStringFromObject('warnings', task);
    const description = getStringFromObject('description', task);
    const message = (
        <React.Fragment>
            {
                errors &&
                <Grid container>
                    <div>
                        <h3 style={{ color: 'black' }}>Errors:</h3>
                        <h3 style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{errors}</h3>
                    </div>
                </Grid>
            }
            {
                warnings &&
                <Grid container>
                    <div>
                        <h3 style={{ color: 'black' }}>Warnings:</h3>
                        <h4 style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{warnings}</h4>
                    </div>
                </Grid>
            }
            {
                description &&
                <Grid container>
                    <div>
                        <h3 style={{ color: 'black' }}>Description:</h3>
                        <h4 style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{description}</h4>
                    </div>
                </Grid>
            }
        </React.Fragment>
    );
    dispatcher(showConfirmationDialog(message, 'Errors And Warnings'));
};

export const handleDownloadClick = (e, token) => {
    // e.preventDefault();
    e.stopPropagation();
    window.open(`${api.FILE.DOWNLOAD_WITH_TOKEN_CHUNKED}${token}`, '_blank');
};

export const reprocessClaimOrBundleToProcessDownload = (bundleOrClaim, dispatcher) => {
    const task = getStringFromObject('downloadTask', bundleOrClaim, null);
    let element = '';
    if (isObjectValidAndNotEmpty(task)) {
        if (task.status === 'READY') {
            element = 'Ready to process...';
        } else if (task.status === 'ACTIVE') {
            element = 'compressing...';
        } else if (task.status === 'FAILED') {
            element = (
                <Tooltip title="Click to show errors/warnings">
                    <div
                        style={{ color: 'red' }}
                        className="cursor-pointer"
                        onClick={e => showErrorAndWarnings(e, task, dispatcher)}
                    >
                        Failed !! <WarningIcon />
                    </div>
                </Tooltip>
            );
        } else if (task.fileToken) {
            element = (
                <div className="display-flex">
                    <Tooltip title="Click to download">
                        <img
                            onClick={e => handleDownloadClick(e, task.fileToken)}
                            src={`${import.meta.env.BASE_URL}/svgicons/Download_blue.svg`}
                            alt=""
                            style={iconStyle}
                        />
                    </Tooltip>
                    <div
                        className="ml0-5"
                    >
                        <DoneAll nativeColor="#00ff00" />
                    </div>
                </div>
            );
        }
    }
    const policyStatementDownloadTask = getStringFromObject('policyStatementDownloadTask', bundleOrClaim);
    let isPolicyStatementDone = false;
    if (isObjectValidAndNotEmpty(policyStatementDownloadTask)) {
        const { status } = policyStatementDownloadTask;
        isPolicyStatementDone = ['COMPLETED', 'FAILED'].includes(status);
    }
    return {
        ...bundleOrClaim,
        isPolicyStatementDone,
        downloadStatus: element,
    };
}

export const printCoverLetterApi = api.BUNDLES.PRINT_COVER_LETTER;
export const printTaxSubmissionLetterApi = api.BUNDLES.PRINT_TAX_SUBMISSION_LETTER;

export const getIssueInvoiceMenuActions = ({ opticalSalesAmount, pharmacySalesAmount }) => {
    const options = [];
    if (NumberOf(pharmacySalesAmount) > 0) {
        options.push(
            { key: 'issuePharmacyInvoice', value: 'ISSUE PHARMACY INVOICE' },
        );
    }
    if (NumberOf(opticalSalesAmount) > 0) {
        options.push(
            { key: 'issueOpticalInvoice', value: 'ISSUE OPTICAL INVOICE' },
        );
    }
    return options;
};
