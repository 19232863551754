import React from 'react';
import Grid from '@material-ui/core/Grid';
import uuidv4 from 'uuid/v4';
import cloneDeep from 'clone-deep';

import FormikTextField from '../Formik/FieldComponents/FormikTextField';
import API from '../../constants/api';
import {
    isArrayValidAndNotEmpty, sortArrayOfObjectsByFieldValue,
    sortArrayOfObjectsByStringValue,
} from '../../constants/CommonUtil';
import { convertIntoTimeFormat } from '../Attendance/AttendanceListUtil';
import { getStringFromObject, setStringPropertyToObject } from '../../constants/lodashUtils';

/**
 * Created by vinay on 15/4/19.
 */

const payslipBatchStatuses = {
    DRAFT: 'draft',
    PROCESSED: 'processed',
};

export const PAYSLIP_BATCHES_FILTERS = [
    {
        name: 'period',
        label: 'Period',
        type: 'select',
        defaultValue: '',
        dataSourceApi: `${API.PAYROLL.ALL_PERIODS}?size=36&searchString=`,
        dataSourceConfig: {
            text: 'display',
            value: 'uuid',
        },
    },
    {
        name: 'status',
        label: 'Status',
        type: 'select',
        defaultValue: '',
        dataSourceConfig: {
            text: 'value',
            value: 'key',
        },
        options: Object.keys(payslipBatchStatuses).map(aStatus => ({
            key: payslipBatchStatuses[aStatus],
            value: aStatus,
        })),
    },
    {
        name: 'createDate.from',
        label: 'Created Date From',
        type: 'date',
        maxDateField: 'createDate.to',
        defaultValue: '',
    },
    {
        name: 'createDate.to',
        label: 'Created Date To',
        type: 'date',
        minDateField: 'createDate.from',
        defaultValue: '',
    },
];

export const payslipStates = {
    CREATED: 'CREATED',
    SUBMITTED: 'SUBMITTED',
    PAID: 'PAID',
    PARTLY_PAID: 'PARTLY_PAID',
};

const PAYSLIP_LIST_STATES = [
    { key: payslipStates.CREATED, value: 'Created' },
    { key: payslipStates.SUBMITTED, value: 'Submitted' },
    { key: payslipStates.PAID, value: 'Paid' },
    { key: payslipStates.PARTLY_PAID, value: 'Partly Paid' },
];

export const PAYSLIP_LIST_FILTERS = [
    {
        name: 'period',
        label: 'Period',
        type: 'select',
        defaultValue: '',
        dataSourceApi: `${API.PAYROLL.ALL_PERIODS}?size=36&searchString=`,
        dataSourceConfig: {
            text: 'display',
            value: 'uuid',
        },
    },
    {
        name: 'employee',
        label: 'Employee',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi: `${API.EMPLOYEE.GET_SUGGESTION}?ignoreRetired=true&name=`,
        dataSourceConfig: {
            text: 'value',
            value: 'key',
        },
    },
    {
        name: 'department',
        label: 'Department',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi: API.DEPARTMENT.AUTOSUGGEST_WITH_ALL,
        dataSourceConfig: {
            text: 'value',
            value: 'key',
        },
    },
    {
        name: 'status',
        label: 'Status In',
        type: 'select',
        defaultValue: '',
        multiple: true,
        options: PAYSLIP_LIST_STATES,
        dataSourceConfig: {
            text: 'value',
            value: 'key',
        },
    },
    {
        name: 'createDate.from',
        label: 'Created Date From',
        type: 'date',
        maxDateField: 'createDate.to',
        defaultValue: '',
    },
    {
        name: 'createDate.to',
        label: 'Created Date To',
        type: 'date',
        minDateField: 'createDate.from',
        defaultValue: '',
    },
];

export const PAYMENT_ADVICE_STATES = [
    { key: 'generated', value: 'Generated' },
    { key: 'completed', value: 'Completed' },
];

export const PAYMENT_ADVICE_LIST_FILTERS = [
    {
        name: 'period',
        label: 'Period',
        type: 'select',
        defaultValue: '',
        dataSourceApi: `${API.PAYROLL.ALL_PERIODS}?size=36&searchString=`,
        dataSourceConfig: {
            text: 'display',
            value: 'uuid',
        },
    },
    {
        name: 'employee',
        label: 'Employee',
        type: 'autocomplete',
        defaultValue: '',
        dataSourceApi: `${API.EMPLOYEE.GET_SUGGESTION}?ignoreRetired=true&name=`,
        dataSourceConfig: {
            text: 'value',
            value: 'key',
        },
    },
    {
        name: 'createDate.from',
        label: 'Created Date From',
        type: 'date',
        maxDateField: 'createDate.to',
        defaultValue: '',
    },
    {
        name: 'createDate.to',
        label: 'Created Date To',
        type: 'date',
        minDateField: 'createDate.from',
        defaultValue: '',
    },
    {
        name: 'status',
        label: 'Status',
        type: 'select',
        defaultValue: '',
        options: PAYMENT_ADVICE_STATES,
        dataSourceConfig: {
            text: 'value',
            value: 'key',
        },
    },
];

export const refactorPayslipDtos = (l, withError = true) => ({
    uiUuid: uuidv4(),
    ...l,
    error: withError ? getStringFromObject('computationError', l, false) : false,
    warning: withError ? getStringFromObject('computationWarning', l, false) : false,
    workedHoursDisplay: convertIntoTimeFormat(l.workedHours),
    overtimeHoursDisplay: convertIntoTimeFormat(l.overtimeHours),
    shortageHoursDisplay: convertIntoTimeFormat(l.shortageHours),
});

export const mapPayslipValidatedResponse = (arr) => {
    if (isArrayValidAndNotEmpty(arr)) {
        return arr.map(refactorPayslipDtos);
    }
    return [];
};

export const refactorPayslipBatchDto = (batch) => {
    const isProcessed = getStringFromObject('state', batch) === 'processed';
    let lines = getStringFromObject('payslipDtos', batch);
    if (isArrayValidAndNotEmpty(lines)) {
        lines = lines.map(l => refactorPayslipDtos(l, !isProcessed));
    }
    return ({
        ...batch,
        subCompany: batch.subCompany ? {
            key: batch.subCompany,
            value: batch.subCompany,
        } : null,
        payslipDtos: sortArrayOfObjectsByStringValue(lines, 'employee.value'),
    });
};


export const getTextField = (id, value, label) => (
    <Grid item>
        <FormikTextField
            field={{
                name: id,
                value,
            }}
            label={label}
            variant="outlined"
            disabled
            fullWidth
        />
    </Grid>
);

export const getAllowancesAndDeductionsFromPayslipLines = (payslipLines) => {
    let allowances = [];
    let deductions = [];
    if (isArrayValidAndNotEmpty(payslipLines)) {
        sortArrayOfObjectsByFieldValue(payslipLines, 'sequence').forEach((line) => {
            if (line.deduction) {
                deductions = deductions.concat(line);
            } else {
                allowances = allowances.concat(line);
            }
        });
    }
    return { deductions, allowances };
};

export const updateTotalsInPayslipLines = (payslipInputs, systemCalculated, payslip) => {
    const newPayslip = cloneDeep(payslip);
    const payslipLines = getStringFromObject('payslipLines', newPayslip);
    const payslipLineToCodeMap = {};
    if (isArrayValidAndNotEmpty(payslipLines)) {
        payslipLines.forEach((aLine, index) => {
            const code = getStringFromObject('name', aLine);
            payslipLineToCodeMap[code] = index;
        });
    }
    if (isArrayValidAndNotEmpty(payslipInputs)) {
        payslipInputs.forEach((anInput) => {
            const code = getStringFromObject('ruleInputCode.inputCode', anInput);
            const payslipLineIndex = payslipLineToCodeMap[code];
            if (payslipLineIndex || payslipLineIndex === 0) {
                console.log('asd0a9dia-0das-dasasda', anInput.amount, payslipLineIndex);
                setStringPropertyToObject(
                    `payslipLines[${payslipLineIndex}].total`,
                    newPayslip,
                    anInput.amount,
                );
            }
        });
    }
    if (isArrayValidAndNotEmpty(systemCalculated)) {
        systemCalculated.forEach((aSystemCalculatedInput) => {
            const code = getStringFromObject('name', aSystemCalculatedInput);
            const payslipLineIndex = payslipLineToCodeMap[code];
            if (payslipLineIndex || payslipLineIndex === 0) {
                console.log('asd0a9dia-0das-dasasda', aSystemCalculatedInput.total, payslipLineIndex);
                setStringPropertyToObject(
                    `payslipLines[${payslipLineIndex}].total`,
                    newPayslip,
                    aSystemCalculatedInput.total,
                );
            }
        });
    }
    return newPayslip;
};
