import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, withFormik } from 'formik';
import cloneDeep from 'clone-deep';
import axios from 'axios';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog/Dialog';
import Close from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import {
    apiCatchBlockFunction, getJsonPath,
    roundedValueFixedToTwoDigits,
    valueToFixedTwoDigits,
} from '../../../constants/CommonUtil';
import ActionButton from '../../ActionButton/ActionButton';
import { isEmpty, required } from '../../../constants/FormValidations';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import { errorMessage, successMessage } from '../../../redux/modules/message/message-actions';
import API from '../../../constants/api';
import {
    clearSelectedExpenseInvoice,
    createAccountExpenseRequest,
    fetchAccountExpenseRequestById,
} from '../../../redux/modules/employeeExpense/employeeExpense-actions';
import { getSubTotal, HANDLERS, mapExpenseToUiObject, mapUiObjectToExpense, style } from './EmployeeExpenseUtil';
import Print from '../../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import { APPLICATION_CONFIG_URL } from '../../../constants/constants';
import FormikReactSelectMaterial from '../../Formik/FieldComponents/FormikReactSelectMaterial';
import FormikTextField from '../../Formik/FieldComponents/FormikTextField';
import FormikTable from '../../Formik/FormikTable/FormikTable';
import { displayWarning } from '../../../redux/modules/warningDialog/warningDialog-actions';
import { commonGetApiRequest } from '../../../redux/modules/common/common-actions';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const formName = 'employeeExpenseForm';

class ExpenseForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
            balance: 0,
            print: false,
            journalEntryPrintData: null,
            printJournal: false,
        };
    }

    componentDidMount() {
        fetch(getJsonPath('/BookManagement/EmplyeeExpense.json'))
            .then(resp => (resp.json()))
            .then((json) => {
                this.setState({
                    schema: json,
                });
            })
            .catch((error) => {
                console.error(`There has been a problem with your fetch operation:${error.message}`);
            });

        const { dispatch, expenseId } = this.props;
        if (expenseId) {
            dispatch(fetchAccountExpenseRequestById(expenseId));
        }
    }

    componentDidUpdate(prevProps) {
        const prevEmployeeUuid = getStringFromObject('employee.key', prevProps.selectedExpense);
        const currEmployeeUuid = getStringFromObject('employee.key', this.props.selectedExpense);
        if (prevEmployeeUuid !== currEmployeeUuid) {
            this.handleEmployeeSelect(getStringFromObject('employee', this.props.selectedExpense));
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearSelectedExpenseInvoice());
    }

    printHandler = () => {
        this.setState(prev => ({ print: !prev.print }));
    };

    fetchJournalEntriesAndPrint = () => {
        const { selectedExpense, dispatch } = this.props;
        const { journalEntryPrintData } = this.state;
        const accountMoveUuid = getStringFromObject('accountMoveUuid', selectedExpense);
        if (accountMoveUuid) {
            if (!isObjectValidAndNotEmpty(journalEntryPrintData)) {
                // not fetched.. fetch it first before proceeding
                dispatch(commonGetApiRequest(
                    `${API.ACCOUNT_MOVES.GET_ONE}${accountMoveUuid}`,
                    {
                        successCallback: (res) => {
                            this.setState(p => ({ journalEntryPrintData: res, printJournal: !p.printJournal }));
                        },
                    },
                ));
            } else {
                this.setState(p => ({ printJournal: !p.printJournal }));
            }
        }
    };

    handleEmployeeSelect = (value) => {
        if (value && value.key) {
            axios.get(`${API.SALARYADVANCE.GET_RESIDUE}?employeeUuid=${value.key}&type=PETTY`)
                .then((response) => {
                    this.setState({
                        balance: NumberOf(response.data),
                    });
                })
                .catch((error) => {
                    apiCatchBlockFunction(error, this.props.dispatch);
                });
        }
    };

    undoReconciliationWaring = () => {
        const { dispatch } = this.props;
        dispatch(displayWarning(
            ' Do you want to cancel this expense?',
            this.undoReconciliation,
        ));
    };

    undoReconciliation = async () => {
        const {
            dispatch,
            selectedExpense,
        } = this.props;
        try {
            dispatch(showSpinner());
            const expenseId = getStringFromObject('id', selectedExpense);
            await axios.post(`${API.PETTYCASH.CANCEL_PETTY_CASH_EXPENSE}${expenseId}`);
            dispatch(successMessage('Expense canceled successfully'));
            dispatch(fetchAccountExpenseRequestById(expenseId));
            this.handleEmployeeSelect(getStringFromObject('employee', this.props.selectedExpense));
            dispatch(hideSpinner());
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    handleChangeDiscountPerc = () => {
        const { setFieldValue } = this.props;
        setFieldValue('discountAmount', 0);
    };

    handleChangeDiscountAmount = () => {
        const { setFieldValue } = this.props;
        setFieldValue('discountPer', 0);
    };

    handleConfirm = async () => {
        const {
            submitForm, dispatch, setSubmitting, values, validateForm,
        } = this.props;

        // If there are validation errors, prevent show the warning alert
        const errors = await validateForm(values);
        if (Object.keys(errors).length > 0) {
            setSubmitting(false);
            return errors;
        } else if (
            values.expenseLines.length === 1 &&
            values.expenseLines[0].category === null
        ) {
            setSubmitting(false);
            dispatch(errorMessage('Atleast one line item is required.'));
            return null;
        }
        dispatch(displayWarning(
            'You are about to confirm Petty Cash Expense. Are you sure want to continue ?',
            () => {
                submitForm();
            },
            undefined,
            undefined,
            undefined,
            'Confirm',
            'Cancel',
        ));
        return null;
    };

    handleSave = () => {
        const { setFieldValue, submitForm } = this.props;
        setFieldValue('state', 'draft');
        submitForm();
    };

    handleEdit = () => {
        const { setFieldValue } = this.props;
        setFieldValue('editable', true);
    };

    maxDiscount = (min, max) => (value) => {
        if (!isEmpty(value)) {
            const discount = NumberOf(value);
            if (discount < min) {
                return `value must be more than ${min}`;
            } else if (discount > max) {
                return `value must be less than ${max}`;
            }
        }
        return undefined;
    };

    render() {
        const {
            classes, currency, values,
            company, handleClose, open,
        } = this.props;
        const {
            schema, balance, print,
            journalEntryPrintData,
            printJournal,
        } = this.state;

        const number = getStringFromObject('name', values);
        const isConfirmed = getStringFromObject('state', values) === 'confirmed';
        const readOnly = !getStringFromObject('editable', values, false);
        const { subTotal, discountAmount, total } = getSubTotal(values);
        const printData = {
            ...values,
            pettyCash: true,
            company,
            currency,
            subTotal,
            discountAmount,
            total,
        };
        console.log('Petty Cash Recoup', this.props);
        return (
            <Dialog
                open={open}
                fullScreen
                classes={{
                    paper: classes.paper,
                }}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                    <Grid container justify="space-between">
                        <div>
                            Petty Cash Expense
                            {
                                number && <React.Fragment>({number})</React.Fragment>
                            }
                        </div>
                        <Close className="cursor-pointer" onClick={handleClose} test-id="expense-close" />
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    {
                        !readOnly &&
                        <Grid container>
                            <Grid item>
                                Select an employee with petty cash amount
                            </Grid>
                        </Grid>
                    }
                    <Grid container spacing="2" className="mt-1" justify="space-between">
                        <Grid item lg={3} md={4} sm={5}>
                            <Field
                                testId="employee"
                                name="employee"
                                component={FormikReactSelectMaterial}
                                dataSourceConfig={{
                                    text: 'value',
                                    value: 'key',
                                }}
                                label="Employee name"
                                id="employee"
                                isDisabled={readOnly}
                                placeholder="Choose an Employee"
                                autocomplete
                                validate={required}
                                required
                                onSelectHandlers={['handleEmployeeSelect']}
                                actionHandlers={{
                                    handleEmployeeSelect: this.handleEmployeeSelect,
                                }}
                                dataSourceApi={`${API.SALARYADVANCE.GET_EMPLOYEE}?name=`}
                            />
                        </Grid>
                        {
                            !isConfirmed && readOnly &&
                            <Grid item lg={2} md={4} sm={5}>
                                <Grid container justify="flex-end">
                                    <ActionButton
                                        test-id="expense-edit"
                                        primary
                                        disableRipple
                                        onClick={this.handleEdit}
                                    >
                                        Edit
                                    </ActionButton>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid container spacing={16} className="mt-1" justify="space-between">
                        <Grid item lg={2} md={2} sm={2}>
                            <Field
                                name="date"
                                label="Payment Date"
                                id="date"
                                disabled={readOnly}
                                component={FormikTextField}
                                type="date"
                                required
                            />
                        </Grid>
                        <Grid item lg={5} md={4} sm={5}>
                            {
                                !isConfirmed &&
                                <Grid container justify="flex-end">
                                    <Grid item>
                                        <div style={{ float: 'right' }}>
                                            Amount in Petty cash account
                                        </div>
                                        <div style={{ fontSize: '2em' }}>
                                            {currency}&nbsp;{roundedValueFixedToTwoDigits(balance)}
                                        </div>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {
                        isObjectValidAndNotEmpty(schema) &&
                        <div className="mt-1" style={{ background: '#fff', padding: '2rem' }}>
                            <Grid container>
                                <FormikTable
                                    tableRoot={{ minHeight: '20em', overflow: 'visible' }}
                                    {...schema}
                                    actionHandlers={HANDLERS}
                                    fieldName="expenseLines"
                                    isEditable={!readOnly}
                                    showRowNumber
                                    formValues={values}
                                />
                            </Grid>
                            <Grid container className="mt-1" justify="flex-end">
                                <Grid item lg={3} md={3} sm={4}>
                                    <Field
                                        testId="memo"
                                        name="memo"
                                        component={FormikTextField}
                                        label="memo"
                                        multiline
                                        rows={3}
                                        rowsMax={3}
                                        fullWidth
                                        disabled={readOnly}
                                    />
                                </Grid>
                                <Grid item lg={9} md={9} sm={8}>
                                    <Grid container justify="flex-end">
                                        <Grid item lg={3} md={3} sm={4} alignItems="flex-end">
                                            <Grid container direction="column" spacing={8} className="mt-0-5">
                                                <Grid item>
                                                    <Grid container spacing={8}>
                                                        <Grid item sm={6} lg={6} md={6}>
                                                            <Field
                                                                test-id="expense-discount-perc"
                                                                label="Discount (%)"
                                                                name="discountPer"
                                                                component={FormikTextField}
                                                                type="number"
                                                                fullWidth
                                                                min={0}
                                                                max={100}
                                                                validate={this.maxDiscount(0, 100)}
                                                                disabled={readOnly}
                                                                onChangeHandlers={['handleChangeDiscountPerc']}
                                                                actionHandlers={{
                                                                    handleChangeDiscountPerc: this.handleChangeDiscountPerc,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item sm={6} lg={6} md={6}>
                                                            <Field
                                                                test-id="expense-discount-amount"
                                                                label="Discount Amount"
                                                                name="discountAmount"
                                                                component={FormikTextField}
                                                                validate={this.maxDiscount(0, subTotal)}
                                                                min={0}
                                                                max={subTotal}
                                                                type="number"
                                                                fullWidth
                                                                disabled={readOnly}
                                                                onChangeHandlers={['handleChangeDiscountAmount']}
                                                                actionHandlers={{
                                                                    handleChangeDiscountAmount: this.handleChangeDiscountAmount,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={2} md={3} sm={4}>
                                            <Grid container alignContent="flex-end" direction="column">
                                                <div className={classes.totalField}>
                                                    Subtotal:&nbsp;&nbsp;{valueToFixedTwoDigits(subTotal)}
                                                </div>
                                                <div className={classNames('mt-0-5', classes.totalField)}>
                                                    Discount:&nbsp;&nbsp;{valueToFixedTwoDigits(discountAmount)}
                                                </div>
                                                <hr />
                                                <div className={classNames('mt-1', classes.totalField)}>
                                                    Net Amount:&nbsp;&nbsp; {valueToFixedTwoDigits(total)}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-end">
                                <Grid item lg={3} md={3} sm={4}>
                                    {total > balance && !isConfirmed ?
                                        <div className={classes.totalField} style={{ color: 'red' }}>
                                            Net Amount cannot be more than Balance
                                        </div> : null
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    }
                    <Print
                        data={printData}
                        print={print}
                        url={`${APPLICATION_CONFIG_URL}/HtmlPrint/BookManagement/supplierExpense.html`}
                    />
                    <Print
                        url={`${APPLICATION_CONFIG_URL}/HtmlPrint/JournalPrint/JournalPrint.html`}
                        data={journalEntryPrintData}
                        print={printJournal}
                    />
                </DialogContent>
                <DialogActions className={classes.content}>
                    <React.Fragment>
                        <ActionButton
                            test-id="cancel-pettyexpense"
                            disableRipple
                            primary={false}
                            onClick={handleClose}
                        >
                            Close
                        </ActionButton>
                        {
                            !isConfirmed &&
                            <ActionButton
                                testId="confirm-pettyexpense"
                                className="ml-1"
                                primary
                                disabled={total > balance}
                                disableTool={total < balance}
                                toolTipTitle="Amount cannot be more than balance"
                                disableRipple
                                onClick={this.handleConfirm}
                            >
                                Confirm
                            </ActionButton>
                        }
                        {/* {
                            !readOnly &&
                            <ActionButton
                                testId="save-pettyexpense"
                                className="ml-1"
                                primary
                                disabled={total > balance}
                                disableTool={total < balance}
                                toolTipTitle="Amount cannot be more than balance"
                                disableRipple
                                onClick={this.handleSave}
                            >
                                Save
                            </ActionButton>
                        } */}
                        {
                            readOnly && isConfirmed &&
                            <ActionButton
                                disableRipple
                                className="ml-1"
                                onClick={this.undoReconciliationWaring}
                            >
                                Cancel Expense
                            </ActionButton>
                        }
                        {
                            readOnly && isConfirmed &&
                            <ActionButton
                                test-id="print-pettyexpense"
                                className="ml-1"
                                primary
                                disableRipple
                                onClick={this.printHandler}
                            >
                                Print
                            </ActionButton>
                        }
                        {
                            readOnly && isConfirmed &&
                            <ActionButton
                                test-id="print-journal-pettyexpense"
                                className="ml-1"
                                primary
                                disableRipple
                                onClick={this.fetchJournalEntriesAndPrint}
                            >
                                Print Journal Entries
                            </ActionButton>
                        }
                    </React.Fragment>
                </DialogActions>
            </Dialog>
        );
    }
}

ExpenseForm.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    currency: PropTypes.string,
    company: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    selectedExpense: PropTypes.object,
    expenseId: PropTypes.number,
    values: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
};

ExpenseForm.defaultProps = {
    selectedExpense: {},
    currency: '',
    company: '',
    values: {},
    expenseId: null,
};


const mapStateToProps = state => ({
    currency: getStringFromObject('appConfiguration.currency', state),
    company: getStringFromObject('appConfiguration.companyName', state, ''),
    selectedExpense: getStringFromObject('accountExpenseReducer.selectedExpense', state, null),
});

const handleSubmit = (values, { props }) => {
    props.dispatch(createAccountExpenseRequest(mapUiObjectToExpense(values)));
};

export default connect(mapStateToProps)(withFormik({
    mapPropsToValues: props => cloneDeep(mapExpenseToUiObject(getStringFromObject('selectedExpense', props, {}))),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    handleSubmit,
})(withStyles(style)(ExpenseForm)));
