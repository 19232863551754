const MESSAGES = {
    GENERIC: {
        FETCH_FAILED: 'Failed to fetch list',
        ADD_OR_UPDATE_FAILED: 'Failed to update',
        ADD_OR_UPDATE_SUCCESS: 'Updated successfully',
    },
    TESTS: {
        FETCH_FAILED: 'Failed to fetch tests list',
        ADD_OR_UPDATE_FAILED: 'Failed to update',
        ADD_OR_UPDATE_SUCCESS: 'Updated successfully',
    },
    PANEL: {
        FETCH_FAILED: 'Failed to fetch list',
        ADD_OR_UPDATE_FAILED: 'Failed to update panel',
        ADD_OR_UPDATE_SUCCESS: 'Panel updated successfully',
    },
    PACKAGE: {
        ADD_SUCCESS: 'Package added or updated successfully',
    },
    VISIT_DOCUMENTS: {
        ADD: {
            SUCCESS: 'Visit Documents Added Successfully',
            FAILURE: 'Failed To Add Visit Documents',
        },
    },
    INSURANCE: {
        MIN_COPAY: 'Copay Amount Must Be Atleast ',
        MAX_COPAY: 'Copay Amount Can Be At Most ',
        FLAT_COPAY: 'Flat Deductible Applied ',
    },
    VISIT: {
        CLOSE_VISIT: 'Visit Closed Successfully',
        CLOSE_VISIT_WARNING: 'Do you want to close this visit? Patient will need an active visit for consultation',
        CLEAR_ITEMS_IN_BILLING_WARNING: 'All Items In The Billing Section Will Be Cleared. Do You Wish To Proceed?',
        EPISODE_REFERRAL_PENDING:
            'There is an pending referral for the selected doctor.',
        USE_ACTIVE_EPISODE_PROMPT:
            'This patient has an active episode with the selected doctor. ',
        EPISODE_REFERRAL_PENDING_IN_SAME_DEPT_AND_SPECIALITY:
            'There is an pending referral for a doctor of same dept & speciality.',
        EPISODE_OF_SAME_DEPT_AND_SPECIALITY:
            'There is an episode having someone of the same dept & speciality.',
        ITEM_ALREADY_IN_LIST: 'The Item Is Already Present In The List. Do you wish to add 1 quantity to that item ?',
    },
    ANNOUNCEMENTS: {
        EMPTY: 'No announcements to display !',
    },
    STOCK: {
        FETCH_STOCK_MOVE_FAILED: 'Failed to fetch stock moves.',
        RECEIVE_STOCK: {
            FAILED: 'Failed to create stock move.',
            SUCCESS: 'Successfully received stock.',
        },
        RETURN_STOCK: {
            FAILED: 'Failed to Save',
            SUCCESS: 'Successfully Saved',
        },
    },
    VITALS: {
        ADD_SUCCESS: 'Vitals Added Successfully',
    },
    ORDER: {
        FULFILLED_SUCCESSFULLY: 'Order Has Been Fulfilled Successfully',
        FETCH_DETAILS: 'Error in Fetching Details',
        DELETE_ORDER: 'Error In Deleting Orders',
        UPDATE_ORDER: 'Error in Update Orders',
        OPERATION_SUCCESS: 'Operation Successful',
        OPERATION_UNSUCCESSFUL: 'Operation Not Successful',
        SEND_TO_BILLING_SUCCESS: 'Order is Added For Billing',
    },
    BATCHES: {
        FETCH_FAILED: 'Failed to fetch list of batches.',
        CREATE: {
            FAILED: 'Failed to create Batch.',
            SUCCESS: 'Successfully created Batch.',
        },
    },
    CLAIMS: {
        FETCH_FAILED: 'Failed to fetch list of claims.',
        ARCHIVE_SUCCESS: 'Claim Archived Successfully',
        UNARCHIVE_ARCHIVE_SUCCESS: 'Claim Archived/Unarchived Successfully',
        UNARCHIVE_SUCCESS: 'Claim Unarchived Successfully',
        REVIVE_SUCCESS: 'Claim Revived Successfully',
        CREATE_BUNDLE: {
            FAILED: 'Cannot Create Bundles With Claims Of Different Payers',
            SUCCESS: 'Successfully created claims bundle.',
            MULTIPLE_TPA: 'Cannot Create Bundles For Different TPAs',
            ARCHIVE_SUCCESS: 'Bundle Archived Successfully',
            UNARCHIVE_SUCCESS: 'Bundle Unarchived Successfully',
        },
    },
    PAYORS: {
        FETCH_FAILED: 'Failed to fetch list of payors.',
        CREATE: {
            FAILED: 'Failed to create Payor.',
            SUCCESS: 'Successfully saved.',
        },
        DETAILS: {
            FAILED: 'Failed To Fetch Payor.',
        },
    },
    PAYOR_CATEGORY: {
        FETCH_FAILED: 'Failed to fetch list of payor categories.',
        CREATE: {
            FAILED: 'Failed to create Payor Category.',
            SUCCESS: 'Successfully saved',
        },
        DETAILS: {
            FAILED: 'Failed To Fetch Payor Category.',
        },
    },
    PAYOR_POLICY: {
        FETCH_FAILED: 'Failed to fetch list of policies.',
        CREATE: {
            FAILED: 'Failed to create Payor Category.',
            SUCCESS: 'Successfully saved.',
        },
        DETAILS: {
            FAILED: 'Failed To Fetch Payor Category.',
        },
    },
    PRODUCT_CATEGORY: {
        FETCH_FAILED: 'Failed to fetch list of product categories.',
        CREATE: {
            FAILED: 'Failed to create Product Category.',
            SUCCESS: 'Successfully created Product Category.',
        },
        UPDATE: {
            FAILED: 'Failed to update Product Category.',
            SUCCESS: 'Successfully updated Product Category.',
        },
    },
    ACCOUNTS: {
        FETCH_FAILED: 'Failed to fetch list of accounts.',
        CREATE: {
            FAILED: 'Operation Failed',
            SUCCESS: 'Operation Successful',
        },
        ADJUST_BALANCE: {
            FAILED: 'Account balance could not be adjusted.',
            SUCCESS: 'Successfully adjusted account balance.',
        },
        BUNDLE_INVOICE: {
            FAILED: 'Failed to fetch bundle invoices',
            SUCCESS: 'Successfully fetched bundle invoices',
        },
    },
    JOURNAL_ENTRIES: {
        FETCH_FAILED: 'Failed to fetch list of Journal Entries.',
        CREATE: {
            FAILED: 'Failed to create Journal Entry.',
            SUCCESS: 'Successfully created Journal Entry.',
        },
        POST: {
            FAILED: 'Failed to post Journal Entries.',
            SUCCESS: 'Successfully posted Journal Entries.',
        },
    },
    SUPPLIER: {
        FETCH_FAILED: 'Failed to fetch list of suppliers.',
        CREATE: {
            FAILED: 'Failed to create Supplier.',
            SUCCESS: 'Successfully created Supplier.',
        },
        UPDATE: {
            FAILED: 'Failed to update supplier.',
            SUCCESS: 'Successfully updated supplier.',
        },
    },
    PRODUCT: {
        FETCH_FAILED: 'Failed to fetch list of products.',
        CREATE: {
            FAILED: 'Failed to create Product.',
            SUCCESS: 'Successfully created Product.',
        },
    },
    PRICE_LIST: {
        FETCH_FAILED: 'Failed to fetch price list',
        CREATE: {
            FAILED: 'Failed to save price list.',
            SUCCESS: 'Successfully created Price List.',
        },
    },
    ROOM_DEPARTMENT: {
        FETCH_FAILED: 'Failed to fetch mappings',
        CREATE: {
            FAILED: 'Failed to save mapping.',
            SUCCESS: 'Successfully created Mapping.',
        },
    },
    FORMULARY_LIST: {
        FETCH_FAILED: 'Failed to fetch formulary list',
        CREATE: {
            FAILED: 'Failed to save formulary list.',
            SUCCESS: 'Successfully created formulary List.',
        },
    },
    QUEUE_LIST: {
        ERROR_IN_FETCH: 'Error In Fetch Request',
    },
    CONSUMABLE: {
        FETCH_FAILED: 'Failed to fetch list of consumables.',
        CREATE: {
            FAILED: 'Failed to create Consumable.',
            SUCCESS: 'Successfully created Consumable.',
        },
    },
    PURCHASE_ORDER: {
        FETCH_FAILED: 'Failed to fetch purchase order details.',
        CREATE: {
            FAILED: 'Failed to create purchase order.',
            SUCCESS: 'Successfully created purchase order.',
        },
        UPDATE: {
            FAILED: 'Failed to update purchase order.',
            SUCCESS: 'Successfully updated purchase order.',
        },
        CONFIRM: {
            FAILED: 'Failed to confirm purchase order.',
            SUCCESS: 'Successfully confirmed purchase order.',
        },
    },
    ACCOUNT_INVOICE: {
        FETCH_FAILED: 'Failed to fetch Account Invoice details.',
        CREATE: {
            FAILED: 'Failed to create Account Invoice.',
            SUCCESS: 'Successfully created Account Invoice.',
        },
        UPDATE: {
            FAILED: 'Failed to update Account Invoice.',
            SUCCESS: 'Successfully updated Account Invoice.',
        },
        CONFIRM: {
            FAILED: 'Failed to confirm Account Invoice.',
            SUCCESS: 'Successfully confirmed Account Invoice.',
        },
    },
    ACCOUNT_EXPENSE: {
        FETCH_FAILED: 'Failed to fetch Account Expense details.',
        CREATE: {
            FAILED: 'Failed to create Account Expense.',
            SUCCESS: 'Successfully created Account Expense.',
        },
    },
    ACCOUNT_VOUCHER: {
        FETCH_FAILED: 'Failed to fetch Account Voucher.',
    },
    ERP_TRANSACTION: {
        FETCH_FAILED: 'Failed to fetch list of transactions.',
    },
    PAYMENT: {
        REGISTER_PAYMENT: {
            FAILED: 'Failed to register payment.',
            SUCCESS: 'Successfully registered payment.',
        },
    },
    DEPARTMENT: {
        LIST_FETCH_FAILED: 'Failed to fetch departments.',
        FETCH_ACTIVE_APPOINTMENT_FAILED: 'Failed to fetch active appointments for department.',
        CREATE: {
            SUCCESS: 'Department created successfully.',
            FAILED: 'Failed to create Department.',
        },
        UPDATE: {
            SUCCESS: 'Department updated successfully.',
            FAILED: 'Failed to update Department.',
        },
    },
    SERVICE: {
        LIST_FETCH_FAILED: 'Failed to fetch services.',
        DELETE_PROVIDER_FROM_SERVICE_SUCCESS: 'Successfully deleted provider from service',
        FETCH_ACTIVE_APPOINTMENT_FAILED: 'Failed to fetch active appointments for service.',
        CREATE: {
            SUCCESS: 'Service created successfully.',
            FAILED: 'Failed to create Service.',
        },
        UPDATE: {
            SUCCESS: 'Service updated successfully.',
            FAILED: 'Failed to update Service.',
        },
    },
    RES_PARTNER_TITLE_FETCH_FAILED: 'Failed to fetch Res Partner Titles',
    RES_PARTNER_PAYMENT_TERMS_FETCH_FAILED: 'Failed to fetch Res Partner Payment Terms',
    APPOINTMENT_TYPES_FETCH_FAILED: 'Failed to fetch appointment types',
    SERVICE_WEEK_FETCH_FAILED: 'Failed to fetch service configuration',
    PROVIDER_WEEK_FETCH_FAILED: 'Failed to fetch Doctor\'s availability',
    DOCTOR_SLOT: {
        CREATE: {
            SUCCESS: 'Slots created successfully.',
            FAILED: 'Failed to create slots.',
        },
        UPDATE: {
            SUCCESS: 'Slots updated successfully.',
            FAILED: 'Failed to update slots.',
        },
        DELETE: {
            SUCCESS: 'Slots deleted successfully.',
            FAILED: 'Failed to delete slots.',
        },
        SCHEDULER_JOB_FAILED: 'Failed to run scheduled job to create slots for upcoming weeks.',
    },
    SLOT_TEMPLATE: {
        LIST_FETCH_FAILED: 'Failed to fetch templates.',
        FETCH_FAILED: 'Failed to fetch template details.',
        CREATE: {
            SUCCESS: 'Template created successfully.',
            FAILED: 'Failed to create template.',
        },
        UPDATE: {
            SUCCESS: 'Template updated successfully.',
            FAILED: 'Failed to update template.',
        },
        DELETE: {
            SUCCESS: 'Template deleted successfully.',
            FAILED: 'Failed to delete template.',
        },
    },
    APPOINTMENT: {
        CREATE: {
            SUCCESS: 'Appointment created successfully.',
            FAILED: 'Failed to create appointment.',
        },
        RESCHEDULE: {
            SUCCESS: 'Appointment rescheduled successfully.',
            FAILED: 'Failed to reschedule appointment.',
        },
        CANCEL: {
            SUCCESS: 'Appointment canceled successfully.',
            FAILED: 'Failed to cancel appointment.',
        },
        CONFIRM: {
            SUCCESS: 'Appointment confirmed successfully.',
            FAILED: 'Failed to confirm appointment.',
        },
    },
    ADD_STOCK: {
        FETCH_FAILED: 'Failed to fetch stock details.',
        CREATE: {
            FAILED: 'Failed to save Add Stock.',
            SUCCESS: 'Add Stock is successfully saved',
        },
        UPDATE: {
            FAILED: 'Failed to update Add Stock.',
            SUCCESS: 'Add Stock is successfully updated',
        },
        CONFIRM: {
            FAILED: 'Failed to confirm Add Stock.',
            SUCCESS: 'Add Stock is successfully confirmed',
        },
    },
    ACCOUNT_TRANSFER: {
        FETCH_FAILED: 'Failed to fetch details.',
        CREATE: {
            FAILED: 'Failed to transfer balance.',
            SUCCESS: 'Balance is Transferred Successfully',
        },
    },
    ADVANCED_SALARY: {
        CREATE: {
            FAILED: 'Failed to create Advance Salary',
            SUCCESS: 'Advance Salary created successfully',
        },
    },
    PETTY_CASH: {
        CREATE: {
            FAILED: 'Failed to assign petty cash',
            SUCCESS: 'Petty cash provision successfull',
        },
    },
    RECEIVE_PAYMENT: {
        FETCH_FAILED: 'Failed to fetch details.',
        CREATE: {
            FAILED: 'Failed to register payment.',
            SUCCESS: 'Payment is registered successfully',
        },
    },
    INTERNAL_MOVE: {
        FETCH_FAILED: 'Failed to fetch stock details.',
        CREATE: {
            FAILED: 'Failed to add stock.',
            SUCCESS: 'Stock is successfully added',
        },
        UPDATE: {
            FAILED: 'Failed to update stock.',
            SUCCESS: 'Stock is successfully updated',
        },
        CONFIRM: {
            FAILED: 'Failed to confirm stock.',
            SUCCESS: 'Stock is successfully confirmed',
        },
        REVERSE: {
            FAILED: 'Failed to reverse Stock Picking.',
            SUCCESS: 'Stock Picking is successfully reversed and is in draft state.',
        },
    },
    STOCK_INTENT: {
        FETCH_FAILED: 'Failed to Fetch Intent Details',
        CREATE: {
            FAILED: 'Failed to create intent',
            SUCCESS: 'Intent created successfully',

        },
        UPDATE: {
            FAILED: 'Failed to update intent',
            SUCCESS: 'Intent updated successfully',

        },
        CONFIRM: {
            FAILED: 'Failed to confirm  intent.',
            SUCCESS: 'Stock intent is successfully confirmed',
        },
    },
    MATERIAL_RECEIVE: {
        FETCH_FAILED: 'Failed to Fetch Material Receive Details',
        CREATE: {
            FAILED: 'Failed to create Material Receive',
            SUCCESS: 'Material Receive created successfully',

        },
        UPDATE: {
            FAILED: 'Failed to update Material Receive',
            SUCCESS: 'Material Receive updated successfully',

        },
        CONFIRM: {
            FAILED: 'Failed to confirm  Material Receive.',
            SUCCESS: 'Material Receive is successfully confirmed',
        },
        CANCEL: {
            FAILED: 'Failed to cancel  Material Receive.',
            SUCCESS: 'Material Receive Cancelled',
        },
        REVERT: {
            FAILED: 'Failed to Revert  Material Receive.',
            SUCCESS: 'Material Receive Cancelled',
        },
    },
    RECALL: {
        RECALL_LIST_FETCH_FAILED: 'Failed to fetch recall list.',
        CREATE: {
            SUCCESS: 'Successfully added to recall list',
            FAILED: 'Failed to add to recall list',
        },
        DELETE: {
            SUCCESS: 'Successfully deleted recall',
            FAILED: 'Failed to delete recall',
        },
    },
    APPOINTMENT_SLOT_FETCH_FAILED: 'Failed to fetch appointment slots.',
    APPOINTMENT_DETAILS_FETCH_FAILED: 'Failed to fetch appointment details.',
    WORKFLOW: {
        SUBMITTED: 'E-Form successfully submitted',
        COMPLETED: 'E-Form successfully completed',
    },
    ATTENDANCE: {
        PERIOD_FETCH_FAILED: 'Failed to fetch periods',
        DUTY_LOG_FETCH_FAILED: 'Failed to fetch duty logs',
        SAVE: {
            SUCCESS: 'Successfully updated attendance details',
            FAILED: 'Failed to update attendance details',
        },
        APPROVE: {
            SUCCESS: 'Successfully approved attendance details',
            FAILED: 'Failed to approve attendance details',
        },
        REVERT_APPROVED: {
            SUCCESS: 'Successfully reverted attendance details',
            FAILED: 'Failed to revert attendance details',
        },
    },
    ASSET: {
        ASSET_UPDATE: {
            SUCCESS: 'Asset successfully updated',
            FAILURE: 'Failed to update Asset',
        },
        UNRETIRE: {
            SUCCESS: 'Asset successfully unretired.',
            FAILURE: 'Failed to unretire Asset',
        },
    },
    PREPAID_EXPENSE: {
        CREATE: {
            SUCCESS: 'Prepaid Expense created successfully',
            FAILURE: 'Failed to create Prepaid Expense',
        },
        UPDATE: {
            SUCCESS: 'Prepaid Expense updated successfully',
            FAILURE: 'Failed to update Prepaid Expense',
        },
        COFNIRM: {
            SUCCESS: 'Prepaid Expense confirmed successfully',
            FAILURE: 'Failed to confirm Prepaid Expense',
        },
        CREATE_JOURNAL_ENTRY: {
            SUCCESS: 'Journal Entry created successfully',
            FAILURE: 'Failed to create Journal Entry',
        },
        GENERATE_PERIODIC_LINE_FAIL: 'Failed to generate periodic lines',
        FETCH_FAILED: 'Failed to fetch Prepaid Expense',
    },
    CASH_CLOSE: {
        FETCH_FAILED: 'Failed to fetch Cash Close',
        UPDATE_PRINT_FLAG_FAILED: 'Failed to update Cash Close print status',
        CREATE: {
            SUCCESS: 'Cash Close successfully saved',
            FAILURE: 'Failed to save Cash Close',
        },
    },
    CONSOLIDATED_CASH_CLOSE: {
        FETCH_FAILED: 'Failed to fetch Consildated Cash Close',
        UPDATE_PRINT_FLAG_FAILED: 'Failed to update Consolidated Cash Close print status',
        CREATE: {
            SUCCESS: 'Consolidated Cash Close successfully saved',
            FAILURE: 'Failed to save Consolidated Cash Close',
        },
    },
    FAVOURITE_REPORT: {
        FETCH_FAILURE: 'Failed to fetch favourite reports',
        ADD: {
            SUCCESS: 'Successfully added as favourite',
            FAILURE: 'Failed to add as favourite',
        },
        REMOVE: {
            SUCCESS: 'Successfully removed favourite',
            FAILURE: 'Failed to remove favourite',
        },
    },
    BED_TYPE: {
        FETCH_FAILED: 'Failed to fetch list of Bed Type.',
        CREATE: {
            FAILED: 'Failed to create Bed Type.',
            SUCCESS: 'Successfully created Bed Type.',
        },
        UPDATE: {
            FAILED: 'Failed to update Bed Type.',
            SUCCESS: 'Successfully updated Bed Type.',
        },
    },
};

export default MESSAGES;
