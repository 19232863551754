import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
    getRequiredFieldLabel,
    isArrayValidAndNotEmpty,
    isValidFunction,
} from '../../../constants/CommonUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const styles = () => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    labelDiv: {
        margin: '0 1em 7px 0',
    },
    fieldDiv: {
    },
    input: {
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    disableText: {
        color: '#000000',
    },
});

class FormikTextField extends React.PureComponent {
    onChangeHandler = (value) => {
        const {
            field: { name }, form, onChangeHandlers, actionHandlers,
        } = this.props;
        console.log('as-d0ai-da0dsa[psdkasdak', onChangeHandlers);
        if (isArrayValidAndNotEmpty(onChangeHandlers) && isObjectValidAndNotEmpty(actionHandlers)) {
            onChangeHandlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(value, name, form);
                }
            });
        }
    };

    handleChange = (e) => {
        const { field: { onChange } } = this.props;
        onChange(e);
        this.onChangeHandler(e.target.value);
    };

    handleOnBlur = (e) => {
        e.preventDefault();
        const {
            field: { name, onBlur },
            form,
            onBlurHandlers,
            actionHandlers,
            handleOnBlur,
        } = this.props;
        if (isArrayValidAndNotEmpty(onBlurHandlers) && isObjectValidAndNotEmpty(actionHandlers)) {
            onBlurHandlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(e.target.value, name, form);
                }
            });
        }
        if (isValidFunction(handleOnBlur)) {
            handleOnBlur(e);
        }
        if (isValidFunction(onBlur)) {
            onBlur(e);
        }
    };

    handleRef = (ref) => {
        const { addRef, field: { name } } = this.props;
        console.log('REfISHere', name, addRef);
        if (isValidFunction(addRef)) {
            addRef(name, ref);
        }
    };

    render() {
        const {
            field: {
                name,
                value = '',
            },
            // ---- ignored props (do not pass unnecessary props down) -----
            onChangeHandlers,
            isMandatory,
            headerStyle,
            actionHandlers,
            onBlurHandlers,
            classes,
            // ----------------
            ...otherProps
        } = this.props;
        const {
            // ---- ignored props (do not pass unnecessary props down) -----
            form,
            noLabel,
            disableIf,
            takeChoicesFromRow,
            actionParams,
            onValueChange,
            isComponent,
            paramMap,
            formValues,
            InputLabelProps,
            InputProps,
            multiline,
            variant,
            disabled,
            label,
            required,
            ...remainingProps
            // ----------------
        } = otherProps;
        const touched = getStringFromObject(`touched.${name}`, form, false);
        const error = getStringFromObject(`errors.${name}`, form, '');
        console.log('abjdfhaldfka', name, touched, error, value);
        let labelText = label;
        console.log('formikfielderrorSelecccccccccctr', {
            name, form, error, erros: form?.errors,
        });
        if (label && (required || isMandatory)) {
            labelText = getRequiredFieldLabel(label);
        }
        return (
            <TextField
                ref={ref => this.handleRef(ref)}
                {...remainingProps}
                label={labelText}
                id={name} // required don't remove
                value={value}
                multiline={multiline}
                disabled={disabled}
                onChange={this.handleChange}
                onBlur={this.handleOnBlur}
                error={!!error}
                helperText={error || ''}
                variant={variant}
                InputProps={{
                    ...InputProps,
                    classes: {
                        input: multiline ? {} : classes.input,
                        disabled: disabled ? classes.disableText : null,
                    },
                }}
                InputLabelProps={{
                    shrink: true,
                    ...InputLabelProps,
                }}
            />
        );
    }
}

FormikTextField.propTypes = {
    classes: PropTypes.object.isRequired,
    onChangeHandlers: PropTypes.array,
    onBlurHandlers: PropTypes.array,
    handleOnBlur: PropTypes.func,
    addRef: PropTypes.func,
    actionHandlers: PropTypes.object,
    headerStyle: PropTypes.object,
    isMandatory: PropTypes.bool,
    variant: PropTypes.string,
    /* formik props */
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
};

FormikTextField.defaultProps = {
    onChangeHandlers: [],
    onBlurHandlers: [],
    actionHandlers: {},
    headerStyle: {},
    handleOnBlur: () => {},
    addRef: () => {},
    isMandatory: false,
    variant: 'outlined',
};

export default withStyles(styles)(FormikTextField);
