import API from '../../../constants/api';

export const FETCH_BUNDLE_INVOICE_LIST_REQUEST = '@@bundleInvoice/FETCH_BUNDLE_INVOICE_LIST_REQUEST';
export const FETCH_BUNDLE_INVOICE_LIST_FAILURE = '@@bundleInvoice/FETCH_BUNDLE_INVOICE_LIST_FAILURE';
export const FETCH_BUNDLE_INVOICE_LIST_SUCCESS = '@@bundleInvoice/FETCH_BUNDLE_INVOICE_LIST_SUCCESS';

export const FETCH_BUNDLE_INVOICE_REQUEST = '@@bundleInvoice/FETCH_BUNDLE_INVOICE_REQUEST';
export const FETCH_BUNDLE_INVOICE_FAILURE = '@@bundleInvoice/FETCH_BUNDLE_INVOICE_FAILURE';
export const FETCH_BUNDLE_INVOICE_SUCCESS = '@@bundleInvoice/FETCH_BUNDLE_INVOICE_SUCCESS';

export const fetchBundleInvoices = (page, size, filters, searchFilter, seekDirection, seekParams) => ({
    type: FETCH_BUNDLE_INVOICE_LIST_REQUEST,
    api: API.BUNDLES.GET_INVOICES,
    page,
    size,
    filters,
    searchFilter,
    seekDirection,
    seekParams,
});

export const fetchBundleInvoiceById = (invoiceId) => ({
    type: FETCH_BUNDLE_INVOICE_REQUEST,
    api: `${API.BUNDLES.GET_INVOICE_BY_ID}/${invoiceId}`,
});
