import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import axios from 'axios';
import deepEqual from 'react-fast-compare';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import Close from '@material-ui/icons/Close';
import connect from 'react-redux/lib/connect/connect';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { withFormik } from 'formik';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ClearAll from '@material-ui/icons/ClearAll';

import {
    apiCatchBlockFunction,
    getIndexFromFieldName, getJsonPath,
    getTextFieldChangedValue, getTextFieldValueByNativeEvent,
    isArrayValidAndNotEmpty,
    isEnterKeyPressed, isGroupSeparatorPressed,
    isValidFunction,

} from '../../constants/CommonUtil';
import MaterialTable from '../MaterialTableV2/MaterialTable';
import {
    clearStockMoveByPurchaseOrderState,
    fetchStockMovesRequest,
    receiveStockRequest,
} from '../../redux/modules/stockMove/stockMove-actions';
import {
    getEmptyReceiveStockUiObject,
    getMemoizedPrintData,
    mapReceiveStockDtoToUiObject,
    mapReceiveStockFromUiObject,
    OPERATION,
} from '../../mapper/ReceiveStockMapper';
import ActionButton from '../ActionButton/ActionButton';
import API from '../../constants/api';
import FormikTable from '../Formik/FormikTable/FormikTable';
import EnterBatchDetailsDialog from '../RecieveStockTable/EnterBatchDetailsDialog';
import { showConfirmationDialog } from '../../redux/modules/warningDialog/warningDialog-actions';
import GoodReturnDialogNonInvoicedPO from './GoodReturnDialog/GoodReturnDialogNonInvoicedPO';
import Print from '../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import {
    APPLICATION_CONFIG_URL,
    GROUP_SEPARATOR_CHAR,
    STOCK_MANAGEMENT_PRIVILEGES,
} from '../../constants/constants';
import GenericFilterWithSearch from '../GenericFilterComponent/GenericFilterWithSearch';
import { errorMessage } from '../../redux/modules/message/message-actions';
import { getUiObject } from '../../mapper/PurchaseOrderMapper';
import PurchaseOrderDetails from './PurchaseOrderDetails';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import OutlinedTextField from '../OutlinedTextField';
import { getProductDetailsFromScannedBarcode } from './InternalMovesDialog/InternalMovesUtil';
import { commonGetApiRequest } from '../../redux/modules/common/common-actions';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const formName = 'recieveStockForm';

const style = () => ({
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
    },
});

const pricesSchema = [
    {
        noLabel: true,
        label: 'Cost',
        fieldType: 'textField',
        type: 'number',
        id: 'batchCost',
        variant: 'outlined',
        name: 'batchCost',
        validations: {
            required: true,
            minValue: 0,
            costValidation: true,
        },
        headerStyle: {
            fontSize: '14px',
        },
    },
    {
        noLabel: true,
        label: 'Sale Price',
        fieldType: 'textField',
        type: 'number',
        id: 'batchSalePrice',
        variant: 'outlined',
        name: 'batchSalePrice',
        validations: {
            required: true,
            minValue: 0,
            salePriceValidation: true,
        },
        headerStyle: {
            fontSize: '14px',
        },
    },
    {
        noLabel: true,
        label: 'MRP',
        fieldType: 'textField',
        type: 'number',
        variant: 'outlined',
        id: 'batchMrp',
        name: 'batchMrp',
        validations: {
            required: true,
            minValue: 0,
            mrpValidation: true,
        },
        headerStyle: {
            fontSize: '14px',
        },
    },
];

class RecieveStockDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
            addInventoryDialog: false,
            selectedField: false,
            returnGoods: false,
            print: false,
            searchText: '',
            scannedBarcode: '',
            savedFormValues: null,
            isView: props.isView,
            expanded: [],
            showWarningForZeroOrPartialReceivedItems: false,
            listOfZeroOrPartialReceivedItems: [],
            schemaOfZeroOrPartialReceivedItems: null,
        };
    }

    componentWillMount() {
        const { purchaseOrder } = this.props;
        console.log('dlgasjdfgasjf', purchaseOrder);
        this.getAllBatchesForPurchaseOrderInternal();
        this.initializeForm(mapReceiveStockDtoToUiObject([], purchaseOrder));
    }

    componentDidMount() {
        const { isView } = this.props;
        let jsonApi = getJsonPath('/StockManagement/RecieveStock.json');
        if (isView) {
            jsonApi = getJsonPath('/StockManagement/ReceiveGoods.json');
        }
        fetch(jsonApi)
            .then(resp => resp.json())
            .then((json) => {
                console.log('dkjfkaf', json);
                const schema = cloneDeep(json);
                console.log('asdah0d9saud0sad', isView, this.props.ignoreBatchPrice);
                if (!isView && !this.props.ignoreBatchPrice) {
                    schema.batchSchema.tableCells = schema.batchSchema.tableCells.concat(pricesSchema);
                }
                this.setState({
                    schema,
                });
            })
            .catch((error) => {
                console.error(`There has been a problem with your fetch operation:${error.message}`);
            });
        const jsonschemaOfZeroOrPartialReceivedItems = getJsonPath('/StockManagement/ZeroOrPartialReceivedItems.json');
        fetch(jsonschemaOfZeroOrPartialReceivedItems)
            .then(resp => resp.json())
            .then((json) => {
                const schemaOfZeroOrPartialReceivedItems = cloneDeep(json);
                this.setState({
                    schemaOfZeroOrPartialReceivedItems,
                });
            })
            .catch((error) => {
                console.error(`There has been a problem with your fetch operation: ${error.message}`);
            });
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEqual(nextProps.batchesByPurchaseOrder, this.props.batchesByPurchaseOrder)) {
            this.initializeForm(mapReceiveStockDtoToUiObject(nextProps.batchesByPurchaseOrder,
                nextProps.purchaseOrder));
        }
    }
    componentWillUnmount() {
        this.props.dispatch(clearStockMoveByPurchaseOrderState());
    }

    onChangeScannedBarcode = (_, event) => {
        const changedVal = getTextFieldValueByNativeEvent(event);
        this.setState({
            scannedBarcode: changedVal,
        });
    };

    onChangeBarcodeProductQty = (e) => {
        const changedVal = getTextFieldChangedValue(e);
        this.setState({
            barcodeProductQty: changedVal,
        });
    };

    onBarcodeProductQtyConfirm = async (e) => {
        if (isEnterKeyPressed(e)) {
            // check if the barcode scanned has the product in this list
            console.log('asd0asd-0ais');
            const {
                values,
                setFieldValue,
                dispatch,
                barcodeParser,
            } = this.props;
            const {
                barcodeProductQty,
                selectedField,
                scannedBarcode,
            } = this.state;
            const {
                produceCode,
                batchName,
                expiryDate,
            } = await getProductDetailsFromScannedBarcode(scannedBarcode, dispatch, false, barcodeParser);
            const receiveStock = getStringFromObject('receiveStock', values) || [];
            const {
                fieldToCheckAgainst,
            } = this.getFieldToCheckAgainst();
            const product = receiveStock
                .find(aRow => this.isProductCodeOrGtinMatches(fieldToCheckAgainst, aRow, produceCode));
            if (!isObjectValidAndNotEmpty(product)) {
                dispatch(errorMessage('Product Not Found In Order'));
                return;
            }
            if (!batchName) {
                dispatch(errorMessage('Batch No Is Required'));
                return;
            }
            if (!expiryDate) {
                dispatch(errorMessage('Expiry Date Is Required'));
                return;
            }
            if (NumberOf(barcodeProductQty) < 1) {
                dispatch(errorMessage('Quantity Must Be Atleast 1'));
                return;
            }
            let batchExists = false;
            try {
                const response = await axios.get(`${API.CHECK_IF_BATCH_EXISTS}${produceCode}&batchNo=${batchName}`);
                if (response.data) {
                    batchExists = true;
                }
            } catch (err) {
                console.error('asd09aud0a9s', err);
                batchExists = false;
            }
            const addNewBatch = () => {
                let productGtinMapDto = null;
                if (
                    fieldToCheckAgainst === 'productGtinMapDtoList' &&
                    isArrayValidAndNotEmpty(product.product.productGtinMapDtoList)
                ) {
                    const matchingProductGtinMapDto = product.product.productGtinMapDtoList
                        .find(aProductGtinMapDto => aProductGtinMapDto.gtin === produceCode);
                    if (isObjectValidAndNotEmpty(matchingProductGtinMapDto)) {
                        productGtinMapDto = matchingProductGtinMapDto;
                    }
                }
                const batchDto = {
                    uuid: '',
                    origin: '',
                    batchCost: getStringFromObject('product.cost', product),
                    batchSalePrice: getStringFromObject('product.salePrice', product),
                    batchMrp: getStringFromObject('product.salePrice', product),
                    batchNumber: batchName,
                    batchQuantity: NumberOf(barcodeProductQty),
                    batchExpiryDate: expiryDate,
                    stockProdLot: batchExists ? {
                        expiryDate: new Date(expiryDate).getTime(),
                        key: '',
                        value: batchName,
                    } : null,
                    trackByBatch: true,
                    productGtinMapDto,
                };
                console.log('asdadasud-as0dsa-dsa', batchDto);
                product.batches = (product.batches || []).concat(batchDto);
            };
            if (isArrayValidAndNotEmpty(product.batches)) {
                const batchAlreadyPresent =
                    product.batches.findIndex(aBatch => aBatch.batchNumber === batchName);
                if (batchAlreadyPresent > -1) {
                    product.batches[batchAlreadyPresent] = {
                        ...product.batches[batchAlreadyPresent],
                        batchQuantity: NumberOf(product.batches[batchAlreadyPresent].batchQuantity) + NumberOf(barcodeProductQty),
                    };
                } else {
                    addNewBatch();
                }
            } else {
                addNewBatch();
            }
            setFieldValue(selectedField, product);
            this.clearBarcodeEntry();
        }
    };

    onEdit = async () => {
        const {
            batchesByPurchaseOrder,
            dispatch,
        } = this.props;
        console.log('asd9ai-d0i', batchesByPurchaseOrder);
        try {
            const purchaseOrderUuid = getStringFromObject('[0].purchaseOrder', batchesByPurchaseOrder);
            const api = `${API.STOCK.FETCH_STOCK_MOVES_BY_PURCHASE_ORDER_BY_UUID}${purchaseOrderUuid}`;
            const response = await axios.get(api);
            const response2 = await axios.get(`${API.PURCHASE_ORDER.FETCH_ONE_BY_UUID}${purchaseOrderUuid}`);
            const response3 = await axios.get(getJsonPath('/StockManagement/RecieveStock.json'));
            const formVals = mapReceiveStockDtoToUiObject(response.data, getUiObject(response2.data));
            console.log('asd-a00si-a0id-asd', formVals);
            this.initializeForm(formVals);
            this.setState({
                isView: false,
                schema: response3.data,
            });
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    getFieldToCheckAgainst = () => {
        const barcodeType = this.state.scannedBarcode && (this.state.scannedBarcode[0] || '');
        const isProductOrBatch =
            barcodeType.toLowerCase() === 'p' || barcodeType.toLowerCase() === 'b';
        const fieldToCheckAgainst = isProductOrBatch ? 'productCode' : 'productGtinMapDtoList';
        return {
            isProductOrBatch,
            fieldToCheckAgainst,
        };
    };

    getAllBatchesForPurchaseOrderInternal = () => {
        const {
            dispatch,
            purchaseOrder,
            isView,
            targetId,
        } = this.props;
        let api = `${API.STOCK.FETCH_STOCK_MOVES_BY_PURCHASE_ORDER_BY_UUID}${purchaseOrder.uuid}`;
        if (isView) {
            api = `${API.STOCK.FETCH_STOCK_MOVES_BY_PICKING_ID}${targetId}`;
        }
        dispatch(fetchStockMovesRequest(api));
    };

    getExpandableComponent = (memeber, index) => {
        console.log('jhakjsdghfkjasf', memeber, index);
        const { schema } = this.state;
        const { dispatch, values } = this.props;
        console.log('asdud0a9sduasdasd', getStringFromObject(`${memeber}.batches`, values));
        const length = NumberOf(getStringFromObject(`${memeber}.batches.length`, values, []));
        if (length > 0) {
            return (
                <TableRow>
                    <TableCell colSpan={8} style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                        <FormikTable
                            {...getStringFromObject('batchSchema', schema, {})}
                            isEditable={false}
                            fieldName={`${memeber}.batches`}
                            dispatch={dispatch}
                        />
                    </TableCell>
                </TableRow>
            );
        }
        return '';
    };

    handleKeyDown = async (e) => {
        const { barcodeParser } = this.props;
        const { scannedBarcode } = this.state;
        if (isEnterKeyPressed(e)) {
            // check if the barcode scanned has the product in this list
            const prodInfo = await getProductDetailsFromScannedBarcode(
                this.state.scannedBarcode,
                this.props.dispatch,
                true,
                barcodeParser,
            );
            if (isObjectValidAndNotEmpty(prodInfo)) {
                const { produceCode } = prodInfo;
                const {
                    values,
                    dispatch,
                } = this.props;
                const receiveStock = getStringFromObject('receiveStock', values) || [];
                if (isArrayValidAndNotEmpty(receiveStock)) {
                    const {
                        fieldToCheckAgainst,
                    } = this.getFieldToCheckAgainst();
                    const foundProduct = receiveStock
                        .find(aRow => this.isProductCodeOrGtinMatches(fieldToCheckAgainst, aRow, produceCode));
                    if (foundProduct < 0) {
                        dispatch(errorMessage('Scanned product not found in order'));
                        return;
                    }
                    const state = getStringFromObject('purchaseOrder.state', this.props);
                    const markedAsCompleted = state === 'done' || state === 'cancel';
                    if (markedAsCompleted) {
                        this.props.dispatch(errorMessage('P.O is marked as completed. Cannot Receive Stock'));
                        return;
                    }
                    this.setState({
                        selectedField: `receiveStock[${foundProduct}]`,
                        enterBarcodeProductQty: true,
                    });
                }
            }
        }
        if (isGroupSeparatorPressed(e)) {
            this.setState({
                scannedBarcode: scannedBarcode + GROUP_SEPARATOR_CHAR,
            });
        }
    };

    isProductCodeOrGtinMatches = (fieldToCheck, aRow, productCode) => {
        const valueToCheck = getStringFromObject(`product.${fieldToCheck}`, aRow);
        if (fieldToCheck === 'productGtinMapDtoList') {
            if (isArrayValidAndNotEmpty(valueToCheck)) {
                return valueToCheck.some(aProductGtinMap => aProductGtinMap.gtin === productCode);
            }
        } else {
            return valueToCheck === productCode;
        }
        return false;
    }

    clearBarcodeEntry = () => {
        this.setState({
            selectedField: null,
            enterBarcodeProductQty: false,
            barcodeProductQty: '',
            scannedBarcode: '',
        });
    };

    initializeForm = (formValues) => {
        const { resetForm } = this.props;
        resetForm(formValues);
    };

    updateSavedFormValues = (formValues) => {
        this.setState({
            savedFormValues: formValues,
        });
    };

    handleSave = () => {
        const {
            setFieldValue,
            submitForm,
            values,
            dispatch,
        } = this.props;
        const {
            savedFormValues,
        } = this.state;
        setFieldValue('action', OPERATION.DRAFT);
        setFieldValue('updateSavedFormValues', this.updateSavedFormValues);
        console.log('asda-0i-0i-0sid-0asid0id-as', values, savedFormValues, deepEqual(values, savedFormValues));
        if (deepEqual(values, savedFormValues)) {
            dispatch(errorMessage('No changes made to save'));
            return;
        }
        submitForm();
    };

    handleConfirm = () => {
        const { setFieldValue, values, submitForm } = this.props;
        setFieldValue('action', OPERATION.DONE);
        const updatedReceiveStock = values.receiveStock.map((item) => {
            const currentReceivingQuantity = (item.batches && item.batches.length > 0)
                ? item.batches.reduce((total, batch) => total + (batch.batchQuantity || 0), 0)
                : 0;
            const requiredQuantity = (item.quantity || 0) + item.bonusPo;
            const netReceivingQuantity = (item.received || 0) + (currentReceivingQuantity || 0) - (item.returned || 0);
            let status = '';
            if (netReceivingQuantity === 0) {
                status = 'Not Received';
            } else if (netReceivingQuantity < requiredQuantity) {
                status = 'Partially Received';
            } else {
                status = 'Over Received';
            }
            return {
                ...item,
                currentReceivingQuantity,
                requiredQuantity,
                netReceivingQuantity,
                status,
            };
        });
        const listOfZeroOrPartialReceivedItems = updatedReceiveStock.filter((item) => item.netReceivingQuantity !== item.requiredQuantity);
        if (listOfZeroOrPartialReceivedItems.length > 0) {
            this.setState({
                listOfZeroOrPartialReceivedItems,
                showWarningForZeroOrPartialReceivedItems: true,
            });
        } else {
            submitForm();
        }
    }

    handleButtonAction = (action, field) => {
        console.log('dddddddd', field, action);
        if (action === 'AddNewRow') {
            const state = getStringFromObject('purchaseOrder.state', this.props);
            const markedAsCompleted = state === 'done' || state === 'cancel';
            if (markedAsCompleted) {
                this.props.dispatch(errorMessage('P.O is marked as completed. Cannot Receive Stock'));
                return;
            }
            this.setState({ addInventoryDialog: true, selectedField: field });
        }
    };

    handleCloseAddInventoryDialog = () => {
        this.setState({ addInventoryDialog: false, selectedField: '' });
    };

    handleAddInventory = (batches) => {
        const { selectedField, expanded } = this.state;
        const { setFieldValue, values } = this.props;
        console.log('asd0asid0-asidsa', selectedField, values, expanded);
        const rowValue = cloneDeep(getStringFromObject(selectedField, values, {}));
        // reason for comment not showing balanced and all
        // const orderedQuant = Number(getStringFromObject('quantity', rowValue, 0));
        // const originallyReceived = Number(getStringFromObject('originallyReceived', rowValue, 0));
        // const received = sumBy(batches, item => item.batchQuantity);
        // rowValue.received = originallyReceived + received;
        // rowValue.balanced = orderedQuant - rowValue.received;
        rowValue.batches = batches;
        setFieldValue(selectedField, rowValue);
        let index = getIndexFromFieldName(selectedField);
        if (index != null) {
            index = NumberOf(index);
            this.setState({ expanded: expanded.indexOf(index) === -1 ? expanded.concat(index) : expanded });
        }
        this.handleCloseAddInventoryDialog();
        console.log('jhakjsdghfkjasf', batches);
    };

    handleClickReceiveGoods = () => {
        const { dispatch, purchaseOrder } = this.props;
        const poUuid = getStringFromObject('uuid', purchaseOrder);
        dispatch(commonGetApiRequest(`${API.PURCHASE_ORDER.IS_INVOICED}${poUuid}`, {
            successCallback: (res) => {
                if (res) {
                    dispatch(showConfirmationDialog('Invoice is already confirmed. Please cancel invoice from books application to return the goods. Use the Credit memo feature, If you want to return stock without cancelling the invoice.'));
                } else {
                    this.setState({ returnGoods: true });
                }
            },
        }));
    };

    handleCloseGoodReturnDialog = () => {
        this.setState({ returnGoods: false }, this.getAllBatchesForPurchaseOrderInternal);
    };

    printHandler = () => {
        this.setState(prev => ({ print: !prev.print }));
    };

    handleSearch = (filters) => {
        this.setState({ searchText: getStringFromObject('searchText', filters) });
    };

    rowExpandHandler = (expanded) => {
        this.setState({ expanded });
    };

    filterWithSearchText = (searchText, arr) => {
        let val = searchText || null;
        if (val !== null && val.length > 0 && isArrayValidAndNotEmpty(arr)) {
            val = val.trim().toLowerCase();
            return arr.filter((item) => {
                const product = getStringFromObject('name', item);
                const batchName = getStringFromObject('batchName', item);
                return (
                    (product && product.toLowerCase().includes(val)) ||
                    (batchName && batchName.toLowerCase().includes(val))
                );
            });
        }
        return arr;
    };

    handleProceed = () => {
        this.setState({ showWarningForZeroOrPartialReceivedItems: false });
        this.props.submitForm();
    };

    handleCancel = () => {
        this.setState({ showWarningForZeroOrPartialReceivedItems: false });
    };

    render() {
        const {
            open,
            handleClose,
            classes,
            batchesByPurchaseOrder,
            header,
            values,
            setFieldValue,
            dispatch,
            purchaseOrder,
        } = this.props;
        const {
            schema,
            addInventoryDialog,
            selectedField,
            returnGoods,
            searchText,
            isView,
            expanded,
            scannedBarcode,
            barcodeProductQty,
            enterBarcodeProductQty,
            showWarningForZeroOrPartialReceivedItems,
            listOfZeroOrPartialReceivedItems,
            schemaOfZeroOrPartialReceivedItems,
        } = this.state;
        let viewPicking = {};
        if (isArrayValidAndNotEmpty(batchesByPurchaseOrder) && isView) {
            [viewPicking] = batchesByPurchaseOrder;
        }
        const isDone = getStringFromObject('state', purchaseOrder) === 'done';
        const shoudReturnGoods = !isDone && getStringFromObject('isStockReceived', values, false);
        console.log('RecieveStockDialog props', this.props);
        console.log('printData', values);
        let listData = [];
        if (isView) {
            listData = this.filterWithSearchText(searchText,
                getStringFromObject('stockPickingLinesDTOSet', viewPicking, []));
        }
        return (
            <React.Fragment>
                <form>
                    <Dialog
                        open={open}
                        fullScreen
                        classes={{
                            paper: classes.paper,
                        }}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div>{header}</div>
                                <Close className="cursor-pointer" onClick={handleClose} test-id="recieve-stock-dialog-close" />
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ padding: '0' }}>
                            <div style={{ background: '#fff', padding: '1em 2em 2em' }}>
                                {
                                    shoudReturnGoods &&
                                    checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.returnStock) &&
                                    <Grid container justify="flex-end">
                                        <ActionButton
                                            test-id="recieve-return-stock"
                                            onClick={this.handleClickReceiveGoods}
                                        >
                                            Return Stock
                                        </ActionButton>
                                    </Grid>
                                }
                                {
                                    isView && viewPicking.status === 'draft' &&
                                        <ActionButton
                                            test-id="recieve-stock-edit"
                                            onClick={this.onEdit}
                                        >
                                            Edit
                                        </ActionButton>
                                }
                                <PurchaseOrderDetails
                                    formValues={values}
                                    handleRecieveStock={this.handleRecieveStock}
                                    isView
                                    handleClickPaymentInvoice={this.handleClickPaymentInvoice}
                                    formName={formName}
                                    dispatch={dispatch}
                                    isEditable={false}
                                    handleMarkAsReceiveStock={this.markReceiveStockClicked}
                                    setFieldValue={setFieldValue}
                                />
                                <Grid container className="mt-1" spacing={16}>
                                    <Grid item sm={4} md={4} lg={4}>
                                        <OutlinedTextField
                                            key={enterBarcodeProductQty}
                                            label="Scan barcode"
                                            value={scannedBarcode}
                                            disabled={enterBarcodeProductQty}
                                            onChange={this.onChangeScannedBarcode}
                                            onKeyDown={this.handleKeyDown}
                                            autoFocus
                                        />
                                    </Grid>
                                    {
                                        enterBarcodeProductQty &&
                                            <React.Fragment>
                                                <Grid item sm={3} md={3} lg={3}>
                                                    <OutlinedTextField
                                                        label="Enter Quantity"
                                                        value={barcodeProductQty}
                                                        onChange={this.onChangeBarcodeProductQty}
                                                        onKeyDown={this.onBarcodeProductQtyConfirm}
                                                        type="number"
                                                        autoFocus
                                                    />
                                                </Grid>
                                                <Grid item sm={2} md={2} lg={2}>
                                                    <IconButton onClick={this.clearBarcodeEntry}>
                                                        <ClearAll />
                                                    </IconButton>
                                                </Grid>
                                            </React.Fragment>
                                    }
                                </Grid>
                                {
                                    isObjectValidAndNotEmpty(schema) && (!isView) &&
                                    <Grid container className="mt-2">
                                        <FormikTable
                                            fieldName="receiveStock"
                                            {...schema}
                                            isExpandable
                                            expanded={expanded}
                                            controlledExpand
                                            rowExpandHandler={this.rowExpandHandler}
                                            getExpandableComponent={this.getExpandableComponent}
                                            deleteIcon={false}
                                            hideCheckBox
                                            handleButtonAction={this.handleButtonAction}
                                            customValidationFunctions={{
                                                validateBatchNumber: this.validateBatchNumber,
                                            }}
                                        />
                                    </Grid>
                                }
                                {
                                    isObjectValidAndNotEmpty(schema) && isView &&
                                        <React.Fragment>
                                            <Grid container className="mt-2" justify="center">
                                                <Grid item lg={3} md={4} sm={6}>
                                                    <GenericFilterWithSearch
                                                        filterValues={{
                                                            searchText,
                                                        }}
                                                        hideFilters
                                                        test-id="recieve-stock-search-header"
                                                        saveFilter={this.handleSearch}
                                                        placeholder="Search with product name or batch"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <MaterialTable
                                                    schema={schema}
                                                    uniqueKey="uuid"
                                                    noPagination
                                                    data={listData}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                }
                            </div>
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            {
                                (!isView) &&
                                    <React.Fragment>
                                        {
                                            !isDone &&
                                                <React.Fragment>
                                                    <ActionButton
                                                        testId="recieve-stock-save"
                                                        disableRipple
                                                        onClick={this.handleSave}
                                                        className="mr-1"
                                                    >
                                                        Save
                                                    </ActionButton>
                                                    <ActionButton
                                                        testId="recieve-stock-confirm"
                                                        disableRipple
                                                        onClick={this.handleConfirm}
                                                        className="mr-1"
                                                    >
                                                        Confirm
                                                    </ActionButton>
                                                </React.Fragment>
                                        }
                                        <ActionButton
                                            testId="recieve-stock-print"
                                            disableRipple
                                            onClick={this.printHandler}
                                        >
                                            Print
                                        </ActionButton>
                                    </React.Fragment>
                            }
                            {
                                isView &&
                                <ActionButton
                                    testId="recieve-stock-printview"
                                    disableRipple
                                    onClick={this.printHandler}
                                >
                                    Print
                                </ActionButton>
                            }
                        </DialogActions>
                    </Dialog>
                </form>
                <Print
                    data={getMemoizedPrintData(batchesByPurchaseOrder, purchaseOrder)}
                    print={this.state.print}
                    url={`${APPLICATION_CONFIG_URL}/HtmlPrint/StockManagement/RecieveStockConsolidated.html`}
                />
                {
                    addInventoryDialog && selectedField &&
                    <EnterBatchDetailsDialog
                        open
                        handleClose={this.handleCloseAddInventoryDialog}
                        batchSchema={getStringFromObject('batchSchema', schema, {})}
                        selectedProduct={getStringFromObject(selectedField, values, {})}
                        handleSave={this.handleAddInventory}
                    />
                }
                {
                    returnGoods &&
                    <GoodReturnDialogNonInvoicedPO
                        open
                        handleClose={this.handleCloseGoodReturnDialog}
                        purchaseOrderUuid={getStringFromObject('uuid', purchaseOrder)}
                    />
                }
                {
                    showWarningForZeroOrPartialReceivedItems && (
                        <Dialog
                            open={showWarningForZeroOrPartialReceivedItems}
                            onClose={this.handleCancel}
                            fullScreen
                            classes={{
                                paper: classes.paper,
                            }}
                            aria-labelledby="form-dialog-title"
                        >
                            <DialogTitle>
                                Warning
                                <IconButton
                                    aria-label="close"
                                    onClick={this.handleCancel}
                                    style={{ position: 'absolute', right: 8, top: 8 }}
                                >
                                    <Close
                                        className="cursor-pointer"
                                        onClose={this.handleCancel}
                                        test-id="received-warning-dialog-close"
                                    />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} style={{ marginBottom: '20px' }}>
                                        <Typography variant="body1">
                                            The following items in the
                                            Purchase Order have mismatches in quantity ordered and received.
                                            Please verify the stock receipt for all listed items before confirming.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MaterialTable
                                            schema={schemaOfZeroOrPartialReceivedItems}
                                            uniqueKey="uiUuid"
                                            noPagination
                                            data={listOfZeroOrPartialReceivedItems}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions style={{ marginBottom: '20px' }}>
                                <ActionButton onClick={this.handleCancel} color="primary">
                                    No, Review Items
                                </ActionButton>
                                <ActionButton onClick={this.handleProceed} color="primary">
                                    Yes, Proceed
                                </ActionButton>
                            </DialogActions>
                        </Dialog>
                    )
                }
            </React.Fragment>
        );
    }
}

RecieveStockDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    purchaseOrderDetails: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    purchaseOrder: PropTypes.object,
    batchesByPurchaseOrder: PropTypes.array,
    isView: PropTypes.bool,
    ignoreBatchPrice: PropTypes.bool,
    targetId: PropTypes.number,
    header: PropTypes.string,
    company: PropTypes.string.isRequired,
    createdDate: PropTypes.string.isRequired,
    /* formik props */
    values: PropTypes.object.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    barcodeParser: PropTypes.string,
};

RecieveStockDialog.defaultProps = {
    purchaseOrder: {},
    isView: false,
    ignoreBatchPrice: false,
    targetId: null,
    batchesByPurchaseOrder: [],
    header: 'Receive Stock',
    barcodeParser: '',
};

const mapStateToProps = state => ({
    batchesByPurchaseOrder: getStringFromObject('stockMove.stockMovesByPurchaseOrder', state, []),
    company: getStringFromObject('appConfiguration.companyName', state),
    ignoreBatchPrice: getStringFromObject('appConfiguration.nuacare_ignore_batch_price', state),
    barcodeParser: getStringFromObject('appConfiguration.barcodeParser', state),
});

const handleSubmitForm = (values, { props }) => {
    const dispatcher = getStringFromObject('dispatch', props);
    const purchaseOrder = getStringFromObject('purchaseOrder', props);
    const payload = mapReceiveStockFromUiObject(values, purchaseOrder);
    const isDraft = getStringFromObject('action', values) === OPERATION.DRAFT;
    const updateSavedFormValues = getStringFromObject('updateSavedFormValues', values, () => {});
    console.log('asd-0aid-asid-asid-sa', updateSavedFormValues, values);
    if (isDraft) {
        const { isView, targetId } = props;
        let api = `${API.STOCK.FETCH_STOCK_MOVES_BY_PURCHASE_ORDER_BY_UUID}${purchaseOrder.uuid}`;
        if (isView) {
            api = `${API.STOCK.FETCH_STOCK_MOVES_BY_PICKING_ID}${targetId}`;
        }
        dispatcher(
            receiveStockRequest(
                payload,
                () => {
                    dispatcher(fetchStockMovesRequest(api));
                    if (isValidFunction(updateSavedFormValues)) {
                        updateSavedFormValues(values);
                    }
                },
            ),
        );
    } else {
        dispatcher(receiveStockRequest(payload, props.handleClose));
    }
};

export default connect(mapStateToProps)(withFormik({
    mapPropsToValues: () => cloneDeep(getEmptyReceiveStockUiObject({})),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    handleSubmit: handleSubmitForm,
})(withStyles(style)(RecieveStockDialog)));
