/* eslint-disable react/no-did-mount-set-state */
/*
    eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events,
    jsx-a11y/no-noninteractive-element-interactions
*/

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import deepEqual from 'react-fast-compare';
import BarChart from '@material-ui/icons/BarChart';
import Apps from '@material-ui/icons/Apps';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Replay from '@material-ui/icons/Replay';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MessageIcon from '@material-ui/icons/Message';
import Notifications from '@material-ui/icons/Notifications';
import Popover from '@material-ui/core/Popover/Popover';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import { Avatar, Backdrop, Paper, Slide, Tooltip } from '@material-ui/core';
import Badge from '@material-ui/core/Badge/Badge';
import { toast } from 'react-toastify';
import { Info, Pause, PlayArrow, Report, Stars, VerifiedUser } from '@material-ui/icons';

import 'react-toastify/dist/ReactToastify.css';
import SideBar from '../constants/SideBar';
import {
    analytics,
    APP_SHORTCUT_PRIVILEGE,
    ATTENDANCE_ACTION,
    dashboard,
    frontDeskRoutes,
    nursingRoutes,
    leftPaneToPageMap,
    pathPrivileges,
    payorList,
    profile as profileRoute,
    settings, locationNotSelected, notificationTypes, queueNotSelected, GLOBAL_PROPERTIES, registrationV2,
    registrationV3,
     payorApproval,
     QUEUE_LOCATION_STATUS,
} from '../constants/constants';
import { getLoggedInName, getLoggedInUserDepartment, getLoggedInUserTitle, getLoggedInUserUuid } from '../constants/state';
import { clearCacheAndLogout, logoutRequest } from '../redux/modules/login/login-actions';
import { formatAMPM, formatDateTimeForDisplay } from '../constants/DateUtil';
import { saveDuty } from '../redux/modules/attendance/attendance-actions';
import {
    apiCatchBlockFunction, getJsonPath,
    getUrlWithApiParams, isArrayValidAndNotEmpty,
    smoothScroll, unsubscribeToChannel,
} from '../constants/CommonUtil';
import ApplicaitonShortcutPopover from './ApplicaitonShortcutPopover';
import { checkIfPrivilegeExistsForUser } from '../constants/privilegeChecker';
import { notifyAppSwitch } from '../redux/modules/appConfiguration/appConfiguration-actions';
import NotificationDialog from './NotificationDialog';
import api from '../constants/api';
import UserSettingsDialog from '../containers/UserSettingsDialog/UserSettingsDialog';
import { toggleRegistrationShopSelect } from '../redux/modules/registrationShop/registrationShop-actions';
import ValidateUserDialog from '../containers/ValidateUserDialog/ValidateUserDialog';
import { errorMessage } from '../redux/modules/message/message-actions';
import Linkify from './Linkify/Linkify';
import { displayWarning } from '../redux/modules/warningDialog/warningDialog-actions';
import { toggleQueueSelect } from '../redux/modules/queue/queue-actions';
import DashboardButton from './DashboardComponent/DashboardButton';
import { getStringFromObject } from '../constants/lodashUtils';
import { getLoggedInUserPhotoUrl } from '../constants/imageUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';

const drawerWidth = 300;

const styles = theme => ({
    badge: {
        background: 'white',
        margin: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 3,
        border: `2px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
        }`,
    },
    root: {
        flexGrow: 1,
        height: '100%',
        zIndex: 1,
        minHeight: '100vh',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    grow: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 32,
    },
    closedMenuButton: {
        transform: 'rotate(0deg)',
        transition: 'transform 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    openMenuButton: {
        transform: 'rotate(180deg)',
        transition: 'transform 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        overflow: 'auto',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            overflow: 'auto',
            width: drawerWidth,
            position: 'relative',
            height: '100%',
        },
        position: 'fixed',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    shopButton: {
        color: '#ffffff',
        marginRight: '8px',
    },
    loggedInUserName: {
        color: '#ffffff',
        minWidth: '10rem',
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        marginTop: '4rem',
    },
    toolBar: {
        padding: '0 14px',
    },
    headerStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    buttonStyle: {
        height: '1.5rem',
        borderRadius: '1rem',
        width: '8em',
        fontSize: '0.7rem',
        padding: '0',
        textTransform: 'initial',
        minHeight: '1rem',
        color: '#1aa0e4',
        border: '1px solid #1aa0e4',
    },
    navbarLogo: {
        borderRadius: '32px!important',
    },
    profilePic: {
        height: '130px',
        width: '130px',
        fontSize: '44px',
        backgroundColor: '#E3EDF4',
        color: '#4BADE9',
        boxShadow: 'inset 0 0px 0px 1px hsla(0, 0%, 0%, 0.1)',
    },
    avatarContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    designationIcon: {
        color: '#787878',
        backgroundColor: '#E3EDF4',
        padding: '2px',
        borderRadius: '24px',
        fontSize: '14px',
    },
    popover: {
        top: 'unset',
    },
    punchInStyle: { textAlign: 'center', color: '#787878' },
    punchInUnavailableStyle: { textAlign: 'center', color: '#78787880' },
});

const notificationChannel = '/topic/notifications';

class NavigationHeader extends React.Component {
    constructor(props) {
        super(props);
        console.log('wkjcnkdjcnkdjc', props);
        this.state = {
            open: false,
            activeTab: 'dashboard',
            anchorEl: null,
            anchorElShortcut: null,
            notificationOpen: false,
            wfNotificationOpen: false,
            userSettingsDialog: false,
            unreadNotificationCount: 0,
            unreadWfNotificationCount: 0,
            todaysAttendance: [],
        };
        this.opentimer = null;
        this.closetimer = null;
        this.myRef = React.createRef();
        this.toolbarRef = React.createRef();
        this.subscription = null;
    }

    componentDidMount() {
        console.log('asd0a9ipskodaa', this.props.history);
        this.fetchNotificationCount();
        this.subscribeToNotifications(this.props);
        this.setState({
            activeTab: getStringFromObject('history.location.pathname', this.props),
        });
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEqual(this.props.stompClient, nextProps.stompClient)) {
            unsubscribeToChannel(this.subscription);
            this.subscribeToNotifications(nextProps);
        }
    }

    componentWillUnmount() {
        unsubscribeToChannel(this.subscription);
    }

    onChangeRegShop = () => {
        const {
            updateLocationPrivilege,
        } = this.props;
        const shopToDisplay = this.getShopToDisplay();
        console.log('asdjasdasdja', shopToDisplay);
        if (isArrayValidAndNotEmpty(updateLocationPrivilege) && shopToDisplay !== locationNotSelected) {
            if (checkIfPrivilegeExistsForUser(updateLocationPrivilege)) {
                this.props.dispatch(toggleRegistrationShopSelect());
            } else {
                this.toggleValidateUser();
            }
        } else {
            this.props.dispatch(toggleRegistrationShopSelect());
        }
    };

    handleChangeQueue = () => {
        this.props.dispatch(toggleQueueSelect(false));
    };

    onListItemClick = (value, label) => {
        this.setState({
            activeTab: value,
        });
        this.props.history.push(leftPaneToPageMap[value]);
        this.props.dispatch(notifyAppSwitch(label));
        this.handleDrawerClose();
        setTimeout(() => {
            smoothScroll(0);
        }, 1000);
    };

    onClearCacheAndLogout = () => {
        const { dispatch } = this.props;
        dispatch(displayWarning(
            'Do you want to clear all the data and log out?',
            () => {
                dispatch(clearCacheAndLogout());
            },
        ));
    };
    onLogout = () => {
        this.props.dispatch(logoutRequest());
        this.handleClose();
        console.log('2138iasnkd', this.props.history);
        this.props.history.push('/');
    };

    onReceiveNewNotification = (payload) => {
        console.log('sad-a0uds0-dsad', payload.body);
        const newNotifications = JSON.parse(payload.body);
        const {
            mapWorkflowNameToTitle,
        } = this.props;
        if (isObjectValidAndNotEmpty(newNotifications)) {
            const isWfNotification = newNotifications.notificationType === notificationTypes.WORKFLOW;
            const toastMessage = (
                <React.Fragment>
                    <h5 style={{ fontWeight: 550 }}>
                        {
                            isWfNotification ?
                                getStringFromObject(newNotifications.title, mapWorkflowNameToTitle, newNotifications.title)
                                :
                                newNotifications.title
                        }
                    </h5>
                    <div>
                        <Linkify>
                            {newNotifications.message}
                        </Linkify>
                    </div>
                </React.Fragment>
            );
            toast((
                <div
                    onClick={this.handleNotificationIconClick}
                >
                    {toastMessage}
                </div>
            ), {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 999999999999999999999999999999999999999999999999999999999999999999999999,
                draggable: false,
            });
            if (isWfNotification) {
                this.setState(prevState => ({
                    unreadWfNotificationCount: prevState.unreadWfNotificationCount + 1,
                }));
            } else {
                this.setState(prevState => ({
                    unreadNotificationCount: prevState.unreadNotificationCount + 1,
                }));
            }
        }
    };

    getShopToDisplay = () => {
        const locationPath = getStringFromObject('history.location.pathname', this.props);
        let shopToDisplay = null;
        if (frontDeskRoutes.includes(locationPath)) {
            shopToDisplay = getStringFromObject('registrationShop', this.props) || locationNotSelected;
        } else if (nursingRoutes.includes(locationPath)) {
            shopToDisplay = getStringFromObject('nurseShop', this.props) || locationNotSelected;
        }
        return shopToDisplay;
    };

    getQueueToDisplay = () => {
        const { queueData } = this.props;
        const queue = getStringFromObject('queue', queueData);
        const queueToDisplay = (queue && `Queue: ${queue.value}`) || queueNotSelected;
        return queueToDisplay;
    };

    getQueueStatusIcon = () => {
        const { queueData } = this.props;
        const status = getStringFromObject('status', queueData);
        switch (status) {
        case QUEUE_LOCATION_STATUS.ACTIVE:
            return <PlayArrow className="ml-0-2" />;
        case QUEUE_LOCATION_STATUS.BREAK:
            return <Pause className="ml-0-2" />;
        case QUEUE_LOCATION_STATUS.OFFLINE:
            return <Report className="ml-0-2" />;
        default:
            return '';
        }
    };

    fetchNotificationCountInternal = (ignoreTypes = [], typesIn = []) => new Promise((resolve, reject) => {
        axios.get(getUrlWithApiParams(api.NOTIFICATIONS.COUNT_OF_UNREAD, { ignoreTypes, typesIn }))
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                apiCatchBlockFunction(error, this.props.dispatch);
                reject(error);
            });
    });

    fetchNotificationCount = () => {
        this.fetchNotificationCountInternal([[notificationTypes.WORKFLOW]]).then((count) => {
            this.setState({
                unreadNotificationCount: count,
            });
        });
    };

    fetchWfNotificationCount = () => () => {
        this.fetchNotificationCountInternal([], [notificationTypes.WORKFLOW]).then((count) => {
            this.setState({
                unreadWfNotificationCount: count,
            });
        });
    };

    validateUserFunction = async (loginCredentials) => {
        const {
            updateLocationPrivilege,
            dispatch,
        } = this.props;
        try {
            const response = await axios.post(api.LOGIN.VALIDATE_USER_AND_GET_PRIVILEGES, loginCredentials);
            const privilegesOfUser = response.data;
            console.log('asd-0asod0-as-0dad', updateLocationPrivilege, privilegesOfUser);
            if (isArrayValidAndNotEmpty(privilegesOfUser)) {
                for (let i = 0; i < updateLocationPrivilege.length; i += 1) {
                    const aPrivilege = updateLocationPrivilege[i];
                    if (!privilegesOfUser.includes(aPrivilege)) {
                        dispatch(errorMessage('User does not have sufficient privilege to perform this operation'));
                        return;
                    }
                }
                this.toggleValidateUser();
                this.props.dispatch(toggleRegistrationShopSelect());
            } else {
                dispatch(errorMessage('Username or password does not match'));
            }
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    toggleDrawer = () => {
        this.setState({ open: !this.state.open });
    };

    handleDrawerOpen = () => {
        if (this.opentimer === null && !this.state.open) {
            this.opentimer = setTimeout(() => {
                this.setState({ open: true });
                this.opentimer = null;
            }, 300);
        }
        if (this.closetimer !== null) {
            clearTimeout(this.closetimer);
            this.closetimer = null;
        }
    };

    handleNotificationIconClick = () => {
        this.setState({
            notificationOpen: true,
            wfNotificationOpen: false,
        });
        console.log('iabnibddv', this.state.notificationOpen);
    };

    handleWfNotificationIconClick = () => {
        this.setState({
            wfNotificationOpen: true,
            notificationOpen: false,
        });
    };

    handleDrawerClose = () => {
        if (this.opentimer !== null) {
            clearTimeout(this.opentimer);
            this.opentimer = null;
        }
        if (this.closetimer === null && this.state.open) {
            this.closetimer = setTimeout(() => {
                this.setState({ open: false });
                this.closetimer = null;
            }, 500);
        }
    };

    showAndHideMenuHandler = (event) => {
        this.setState({ anchorEl: event.currentTarget });
        this.fetchTodaysAttendanceDetails();
    };

    subscribeToNotifications = (props) => {
        const {
            stompClient,
        } = props;
        if (stompClient) {
            console.log('asda9dpjuas;dsad', stompClient);
            try {
                this.subscription =
                    stompClient.subscribe(`${notificationChannel}/${getLoggedInUserUuid()}`, this.onReceiveNewNotification);
            } catch (e) {
                console.error('subscription error', e);
            }
        }
    };

    handleHomeIcon = () => {
        const { history } = this.props;
        history.push(dashboard);
    };

    handleChartIconClick = () => {
        const { history } = this.props;
        history.push(analytics);
    };

    handleShortcutPopover = (event) => {
        this.setState({ anchorElShortcut: event.currentTarget });
    };

    handleCloseShortcutPopover = () => {
        this.setState({ anchorElShortcut: null });
    };

    handleNotificationClose = () => {
        this.setState({ notificationOpen: false, wfNotificationOpen: false });
    };

    handleUserSettingsDialogToggle = () => {
        this.setState(prevState => ({ userSettingsDialog: !prevState.userSettingsDialog }));
    };

    handleDuty = (action) => {
        let message = 'Successfully Duty Signed In';
        if (action === ATTENDANCE_ACTION.SIGN_OUT) {
            message = 'Successfully Duty Signed Out';
        }
        const { dispatch } = this.props;
        dispatch(saveDuty({ action }, message));
    };

    handleShortcutApp = (url, appname) => {
        window.open(`${url}`, appname);
    };

    handleSettingIcon = () => {
        const { history, appConfiguration } = this.props;
        const settingSideTabs = getStringFromObject('settingSideTabs', appConfiguration);
        const checkPrivForSettings = getStringFromObject('checkPrivForSettings', appConfiguration);
        // find the first setting tab for which we have privilege
        if (checkPrivForSettings && isObjectValidAndNotEmpty(settingSideTabs)) {
            const settingTabs = Object.values(settingSideTabs);
            for (let i = 0; i < settingTabs.length; i += 1) {
                const aSettingTab = settingTabs[i];
                if (checkIfPrivilegeExistsForUser(aSettingTab.privilege)) {
                    const route = getStringFromObject('tabs[0].route', aSettingTab);
                    console.log('asd-0jasdopandamda', aSettingTab.privilege);
                    if (route) {
                        history.push(route);
                        break;
                    }
                }
            }
        } else {
            history.push(payorList);
        }
    };

    toggleValidateUser = () => {
        this.setState(prevState => ({
            showValidateUsername: !prevState.showValidateUsername,
        }));
    };

    attendanceFilter = (attendanceList, type) => attendanceList.filter(e => e.action === type).sort((a, b) => a.name - b.name).map(e => e.name);

    fetchTodaysAttendanceDetails = async () => {
        const {
            dispatch,
        } = this.props;
        try {
            const response = await axios.get(api.ATTENDANCE.FETCH_DUTY_LOGS);
            const attendancePunches = (getStringFromObject('data[0].attendancePunchs', response) || []);
            const attendancePunchesSignIn = this.attendanceFilter(attendancePunches, 'SIGN_IN');
            const attendancePunchesSignOut = this.attendanceFilter(attendancePunches, 'SIGN_OUT');
            const attendanceData = attendancePunchesSignIn.map((e, i) => [e, attendancePunchesSignOut[i]]);
            this.setState({
                todaysAttendance: attendanceData,
            });
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    render() {
        const {
            classes,
            theme,
            profile,
            appShortcuts,
            appConfiguration,
            children,
            updatePreRouteChangeCheck,
            history,
            enableRegistrationQueue,
            enableApprovalQueue,
            disablePartialRatioRouteMatching,
        } = this.props;
        const {
            open,
            activeTab,
            anchorEl,
            anchorElShortcut,
            unreadNotificationCount,
            unreadWfNotificationCount,
            userSettingsDialog,
            showValidateUsername,
            todaysAttendance,
        } = this.state;
        const { currentUserData } = profile;
        let message = 'Last sign in at';
        if ((!currentUserData.dutyAction) || currentUserData.dutyAction === ATTENDANCE_ACTION.SIGN_OUT) {
            message = 'Last sign out at';
        }
        const lastDutyTime = getStringFromObject('lastDutyTime', currentUserData, new Date().getTime());
        console.log('cvksdvhbdskvhbkd,', theme, this.props.NavbarBottomBar, this.props.history, lastDutyTime);
        const locationPath = getStringFromObject('history.location.pathname', this.props);
        console.log('asd-0ai-d0ai-d0asd', locationPath, appShortcuts);

        const shopToDisplay = this.getShopToDisplay();
        const queueToDisplay = this.getQueueToDisplay();
        const childrenWithProps = React.Children.map(children, child =>
            child && React.cloneElement(child, { updatePreRouteChangeCheck }),
        );
        console.log('jdhdjfhdjhfdhf', enableRegistrationQueue);
        const supportPageLink = getStringFromObject('supportPageLink', appConfiguration);
        console.log('NavigationHeaderPrint', this.myRef);
        const profileButtonHeight = getStringFromObject('current.clientHeight', this.toolbarRef);
        console.log('flushfshdjshdjshdlush', this.toolbarRef, profileButtonHeight);
        const rootRef = document.getElementById('root');
        console.log('NavigationHeaderPrint', rootRef);
        const profileImage = getLoggedInUserPhotoUrl();

        const showQueueStatusIcon =
            (locationPath.includes(registrationV2) || locationPath.includes(registrationV3));

        const showQueueButton = (
            (locationPath.includes(registrationV2) || locationPath.includes(registrationV3)) && enableRegistrationQueue)
            || (enableApprovalQueue && locationPath.includes(payorApproval));


        return (
            <div ref={this.myRef} className={classes.root}>
                {
                    !!anchorEl &&
                    <Backdrop
                        open={!!anchorEl}
                        onClick={this.handleClose}
                        style={{ backdropFilter: 'blur(5px)', zIndex: 9999 }}
                    />
                }
                <AppBar
                    position="absolute"
                    className={classes.appBar}
                    elevation={2}
                >
                    <Toolbar disableGutters className={classes.toolBar}>
                        <div className={classes.headerStyle} ref={this.toolbarRef}>
                            <Grid container spacing={16} className="display-flex align-center">
                                <Grid item sm={4} md={5} lg={4} >
                                    <div className="display-flex">
                                        <IconButton
                                            testId="navigation-menu-icon"
                                            color="inherit"
                                            aria-label="open drawer"
                                            onClick={this.toggleDrawer}
                                            className={
                                                classNames(
                                                    classes.menuButton,
                                                    open && classes.openMenuButton,
                                                    !open && classes.closedMenuButton,
                                                )
                                            }
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                        <IconButton className={classes.navbarLogo} onClick={this.handleHomeIcon}>
                                            <img
                                                src={`${import.meta.env.BASE_URL}/navbarLogo.png`}
                                                alt=""
                                            />
                                        </IconButton>
                                    </div>
                                </Grid>
                                <Grid item sm={1} md={1} lg={1} />
                                <Grid item sm={7} md={7} lg={7} className="display-flex justify-content-right">
                                    <div>
                                        {
                                            showQueueButton &&
                                            <Button
                                                variant="outlined"
                                                size="medium"
                                                test-id="queue-select-button"
                                                className={classes.shopButton}
                                                onClick={this.handleChangeQueue}
                                            >
                                                {queueToDisplay}
                                                {showQueueStatusIcon && this.getQueueStatusIcon()}
                                            </Button>
                                        }
                                        {
                                            [...frontDeskRoutes, ...nursingRoutes].includes(locationPath) &&
                                                <Button
                                                    variant="outlined"
                                                    size="medium"
                                                    test-id="registration-shop-name-button"
                                                    className={classes.shopButton}
                                                    onClick={this.onChangeRegShop}
                                                >
                                                    {shopToDisplay}
                                                </Button>
                                        }
                                        <IconButton
                                            test-id="workflow-message"
                                            aria-haspopup="true"
                                            onClick={this.handleWfNotificationIconClick}
                                            color="inherit"
                                        >
                                            {
                                                unreadWfNotificationCount ?
                                                    <Badge badgeContent={unreadWfNotificationCount} color="secondary">
                                                        <MessageIcon nativeColor="#ffffff" />
                                                    </Badge> :
                                                    <MessageIcon nativeColor="#ffffff" />
                                            }
                                        </IconButton>
                                        <IconButton
                                            test-id="notification"
                                            aria-haspopup="true"
                                            onClick={this.handleNotificationIconClick}
                                            color="inherit"
                                        >
                                            {
                                                unreadNotificationCount ?
                                                    <Badge badgeContent={unreadNotificationCount} color="secondary">
                                                        <Notifications nativeColor="#ffffff" />
                                                    </Badge> :
                                                    <Notifications nativeColor="#ffffff" />
                                            }
                                        </IconButton>
                                        {
                                            checkIfPrivilegeExistsForUser(
                                                getStringFromObject('privileges', pathPrivileges[analytics]),
                                            ) &&
                                                <IconButton
                                                    test-id="analytics"
                                                    aria-haspopup="true"
                                                    onClick={this.handleChartIconClick}
                                                    color="inherit"
                                                >
                                                    <BarChart />
                                                </IconButton>
                                        }
                                        {
                                            supportPageLink &&
                                                <Tooltip title="Visit Support Page">
                                                    <IconButton
                                                        test-id="support-page"
                                                        aria-haspopup="true"
                                                        onClick={() => { window.open(supportPageLink); }}
                                                        color="inherit"
                                                    >
                                                        <Info />
                                                    </IconButton>
                                                </Tooltip>
                                        }
                                        {
                                            checkIfPrivilegeExistsForUser([APP_SHORTCUT_PRIVILEGE]) &&
                                                <IconButton
                                                    test-id="administration-billing-lab-pharmacy"
                                                    aria-haspopup="true"
                                                    onClick={this.handleShortcutPopover}
                                                    color="inherit"
                                                >
                                                    <Apps />
                                                </IconButton>
                                        }
                                        {
                                            checkIfPrivilegeExistsForUser(
                                                getStringFromObject('privileges', pathPrivileges[settings]),
                                            ) &&
                                                <IconButton onClick={this.handleSettingIcon} color="inherit" test-id="setting-icon">
                                                    <SettingsIcon />
                                                </IconButton>
                                        }
                                        <Button
                                            test-id="showlogoutbutton"
                                            variant="outlined"
                                            size="medium"
                                            className={classes.loggedInUserName}
                                            onClick={this.showAndHideMenuHandler}
                                        >
                                            {getLoggedInName()}<br />
                                            {getLoggedInUserTitle()}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Toolbar>
                </AppBar>
                <Popover
                    open={!!anchorEl}
                    anchorEl={rootRef}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClose={this.handleClose}
                    disableRestoreFocus
                    transitionDuration={{ enter: 0, exit: 500 }}
                    PaperProps={{
                        style: {
                            backgroundColor: 'transparent',
                            borderRadius: 0,
                            boxShadow: 'none',
                            marginTop: '0 !important',
                            top: 'unset !important',
                            boxSizing: 'border-box',
                            height: '100vh',
                            overflow: 'visible',
                        },
                    }}
                    marginThreshold={0}
                // classes={{ paper: classes.popover }}
                >
                    <Slide
                        in={!!anchorEl}
                        direction="left"
                    >
                        <Paper
                            style={{
                                width: '350px',
                                height: '100vh',
                                borderRadius: 0,
                                padding: '18px',
                                backgroundColor: '#F9FBFC',
                            }}
                        >
                            <Grid container justify="space-between">
                                <Grid container item justify="space-between">
                                    <Grid container item justify="flex-start" xs={6} spacing={8} alignContent="center">
                                        <Grid item>
                                            <IconButton style={{ backgroundColor: '#5470A5', padding: '6px' }} test-id="user-settings" onClick={this.handleUserSettingsDialogToggle}>
                                                <SettingsIcon style={{ fill: '#fff' }} />
                                            </IconButton>
                                        </Grid>
                                        <Grid item>

                                            <Tooltip
                                                title="Clear data and reload"
                                            >
                                                <IconButton onClick={this.onClearCacheAndLogout} style={{ backgroundColor: '#5470A5', padding: '6px' }}>
                                                    <Replay style={{ fill: '#fff' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={6} justify="flex-end" alignContent="center">
                                        <Grid item>
                                            <DashboardButton type="error" onClick={this.onLogout}>
                                            Logout
                                            </DashboardButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container justify="center" style={{ marginTop: '3em' }}>
                                    <Grid item className={classes.avatarContainer} >
                                        <Avatar
                                            alt="Profile Image"
                                            src={profileImage}
                                            className={classes.profilePic}
                                            imgProps={{ onError: (e) => { e.target.src = getJsonPath('/EmployeeDashboard/person.png'); } }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item sm={12} style={{ paddingTop: '1.5em' }} justify="center">
                                    <Grid item>
                                        <p style={{
                                            fontSize: 26,
                                            color: '#224a98',
                                            fontWeight: 500,
                                            padding: 0,
                                            margin: 0,
                                            textAlign: 'center',
                                            textShadow: '0 0 80px hsla(0, 0%, 0%, 0.3)',
                                        }}
                                        >
                                            {getLoggedInName()}
                                        </p>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    justify="center"
                                    spacing={16}
                                    style={{ paddingTop: '0.75em' }}
                                >
                                    <Grid item justify="flex-start">
                                        <Grid container item alignItems="center">
                                            <VerifiedUser className={classes.designationIcon} color="action" />
                                            <span style={{
                                                fontSize: '12px',
                                                color: '#787878',
                                                fontWeight: 400,
                                                paddingLeft: '4px',
                                                margin: 0,
                                                textAlign: 'center',
                                            }}
                                            >
                                                {getLoggedInUserTitle()}
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid item justify="flex-start">
                                        <Grid container item alignItems="center">
                                            <Stars className={classes.designationIcon} color="action" />
                                            <span style={{
                                                fontSize: '12px',
                                                color: '#787878',
                                                fontWeight: 400,
                                                paddingLeft: '4px',
                                                margin: 0,
                                                textAlign: 'center',
                                            }}
                                            >
                                                {getLoggedInUserDepartment()}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container item justify="center" style={{ marginTop: '2em' }}>
                                    <DashboardButton onClick={() => { this.setState({ anchorEl: null }); history.push(`${profileRoute}/current`); }}>
                                        View Profile
                                    </DashboardButton>
                                </Grid>
                                {
                                    lastDutyTime &&
                                        <Grid container item justify="center" style={{ marginTop: '2em', textAlign: 'center' }}>
                                            <span style={{ color: '#787878' }}>
                                                {message} {formatDateTimeForDisplay(new Date(lastDutyTime))}
                                            </span>
                                        </Grid>
                                }
                                <Grid container item justify="center" style={{ marginTop: '3em', textAlign: 'center' }}>
                                    <Grid container item>
                                        <Grid item sm={6}>
                                            <span style={{
                                                fontSize: '14px',
                                                color: '#787878',
                                                fontWeight: 500,
                                                margin: 0,
                                                textAlign: 'center',
                                            }}
                                            >
                                                Punch Ins
                                            </span>
                                        </Grid>
                                        <Grid item sm={6}>
                                            <span style={{
                                                fontSize: '14px',
                                                color: '#787878',
                                                fontWeight: 500,
                                                margin: 0,
                                                textAlign: 'center',
                                            }}
                                            >
                                                Punch Outs
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid container item sm={10} style={{ marginTop: '4px' }}>
                                        <Divider style={{ width: '100%' }} />
                                    </Grid>
                                    <Grid container style={{ maxHeight: '300px', overflow: 'auto' }}>
                                        {
                                            todaysAttendance.map(e => (
                                                <Grid container item style={{ padding: '14px 0' }} key={e}>
                                                    <Grid item sm={6} className={classes.punchInStyle}>
                                                        {formatAMPM(new Date(e[0]))}
                                                    </Grid>
                                                    <Grid item sm={6} className={classes.punchInStyle}>
                                                        {formatAMPM(new Date(e[1]))}
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                    {
                                        todaysAttendance.length === 0 &&
                                        <span className={classes.punchInStyle} style={{ padding: '14px' }}>
                                            There are no punch in/outs!
                                        </span>
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Slide>
                </Popover>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: classNames(classes.drawerPaper,
                            !this.state.open && classes.drawerPaperClose),
                    }}
                    open={this.state.open}
                    onMouseOver={this.handleDrawerOpen}
                    onFocus={() => { }}
                    onMouseOut={this.handleDrawerClose}
                    onBlur={() => { }}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={this.toggleDrawer}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List style={{ padding: '0px' }}>
                        <SideBar
                            onListItemClick={this.onListItemClick}
                            activeTab={activeTab}
                            sideBarTabs={getStringFromObject('sideBarTabs', appConfiguration, {})}
                            disablePartialRatioRouteMatching={disablePartialRatioRouteMatching}
                        />
                    </List>
                </Drawer>
                <main className={classes.content}>
                    {childrenWithProps}
                </main>
                <Popover
                    open={!!anchorElShortcut}
                    anchorEl={anchorElShortcut}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={this.handleCloseShortcutPopover}
                    disableRestoreFocus
                >
                    <ApplicaitonShortcutPopover
                        handleShortcutApp={this.handleShortcutApp}
                        appShortcuts={appShortcuts}
                    />
                </Popover>
                <NotificationDialog
                    open={this.state.notificationOpen}
                    handleClose={this.handleNotificationClose}
                    fetchNotificationCount={this.fetchNotificationCount}
                    ignoreNotificationTypes={[notificationTypes.WORKFLOW]}
                />
                <NotificationDialog
                    open={this.state.wfNotificationOpen}
                    handleClose={this.handleNotificationClose}
                    fetchNotificationCount={this.fetchWfNotificationCount}
                    notificationTypesIn={[notificationTypes.WORKFLOW]}
                    header="Inbox"
                    history={history}
                />
                <UserSettingsDialog
                    open={userSettingsDialog}
                    handleClose={this.handleUserSettingsDialogToggle}
                    dispatch={this.props.dispatch}
                />
                {
                    showValidateUsername &&
                    <ValidateUserDialog
                        dispatch={this.props.dispatch}
                        dialogTitle="Enter Supervisor's Username And Password"
                        onClose={this.toggleValidateUser}
                        validateFunction={this.validateUserFunction}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    appConfiguration: state.appConfiguration,
    stompClient: state.socket,
    registrationShop: getStringFromObject('registrationShop.shop.shopName', state),
    nurseShop: getStringFromObject('registrationShop.nurseShop.shopName', state),
    updateLocationPrivilege: getStringFromObject('appConfiguration.updateLocationPrivilege', state),
    mapWorkflowNameToTitle: getStringFromObject('mapWorkflowNameToTitle', state.eformConfig),
    enableRegistrationQueue: getStringFromObject(`appConfiguration.${GLOBAL_PROPERTIES.QUEUE_REGISTRATION_ENABLED.replace(/\./g, '_')}`, state, false),
    enableApprovalQueue: getStringFromObject(`appConfiguration.${GLOBAL_PROPERTIES.QUEUE_APPROVAL_ENABLED.replace(/\./g, '_')}`, state, false),
    queueData: state.queue,
    disablePartialRatioRouteMatching: getStringFromObject('appConfiguration.disablePartialRatioRouteMatching', state) || false,
});

NavigationHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    appShortcuts: PropTypes.array.isRequired,
    updateLocationPrivilege: PropTypes.array,
    stompClient: PropTypes.object,
    NavbarBottomBar: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    updatePreRouteChangeCheck: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    history: PropTypes.object,
    mapWorkflowNameToTitle: PropTypes.object,
    appConfiguration: PropTypes.object.isRequired,
    queueData: PropTypes.object,
    enableRegistrationQueue: PropTypes.bool,
    enableApprovalQueue: PropTypes.bool,
    disablePartialRatioRouteMatching: PropTypes.bool,
};

NavigationHeader.defaultProps = {
    NavbarBottomBar: false,
    stompClient: null,
    updateLocationPrivilege: [],
    history: {},
    mapWorkflowNameToTitle: {},
    queueData: null,
    enableRegistrationQueue: false,
    enableApprovalQueue: false,
    disablePartialRatioRouteMatching: false,
};

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(NavigationHeader));
