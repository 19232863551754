import {
    FETCH_BUNDLE_INVOICE_LIST_FAILURE,
    FETCH_BUNDLE_INVOICE_LIST_REQUEST,
    FETCH_BUNDLE_INVOICE_LIST_SUCCESS,
    FETCH_BUNDLE_INVOICE_SUCCESS,
} from './bundleInvoice-actions';
import initialStates from '../../initialStates';

const bundleInvoice = (state = initialStates.bundleInvoice, action) => {
    let newState = {};
    switch (action.type) {
    case FETCH_BUNDLE_INVOICE_LIST_REQUEST:
        newState = Object.assign({}, state, { isLoading: true });
        break;
    case FETCH_BUNDLE_INVOICE_LIST_SUCCESS:
        newState = Object.assign({}, state, {
            isLoading: false,
            list: action.data.content,
            page: action.data.page,
            size: action.data.size,
            totalElements: action.data.totalElements,
            filters: action.filters,
            sortOrder: action.sortOrder,
            sortCol: action.sortCol,
            forwardSeekMap: action.data.forwardSeekMap,
            backwardSeekMap: action.data.backwardSeekMap,
            first: action.data.first,
            last: action.data.last,
        });
        break;
    case FETCH_BUNDLE_INVOICE_LIST_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;
    case FETCH_BUNDLE_INVOICE_SUCCESS:
        newState = Object.assign({}, state, {
            selectedInvoice: action.data,
        });
        break;
    default:
        newState = state;
    }
    return newState;
};

export default bundleInvoice;
