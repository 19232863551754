import { call, put } from 'redux-saga/effects';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import { retriable } from '../retriable';
import {
    FETCH_BUNDLE_INVOICE_FAILURE,
    FETCH_BUNDLE_INVOICE_LIST_FAILURE,
    FETCH_BUNDLE_INVOICE_LIST_SUCCESS,
    FETCH_BUNDLE_INVOICE_SUCCESS,
} from '../../redux/modules/bundleInvoice/bundleInvoice-actions';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import MESSAGES from '../../constants/messages';
import { addSeekDirectionAndParamsToUrlParams } from '../../constants/UrlUtil';
import { createSearchConstructForBundleInvoice } from '../../components/Accounts/BundleInvoice/BundleInvoiceUtil';

export function* fetchBundleInvoiceListWorkerSaga(action) {
    try {
        const {
            api,
            page,
            size,
            filters,
            seekDirection,
            seekParams,
        } = action;
        const searchConstruct = createSearchConstructForBundleInvoice(filters);
        const apiWithParams =
            addSeekDirectionAndParamsToUrlParams(`${api}?page=${page}&size=${size}`, seekDirection, seekParams);
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, apiWithParams, searchConstruct);
        yield put({
            type: FETCH_BUNDLE_INVOICE_LIST_SUCCESS,
            data: {
                ...response.data,
                size,
                page,
            },
            filters: action.filters,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        console.log('invoice error', e);
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${MESSAGES.ACCOUNTS.BUNDLE_INVOICE.FAILED}`,
            });
        }
        yield put({ type: FETCH_BUNDLE_INVOICE_LIST_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchBundleInvoiceWorker(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: FETCH_BUNDLE_INVOICE_SUCCESS,
            data: response.data,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${MESSAGES.ACCOUNTS.BUNDLE_INVOICE.FAILED}`,
            });
        }
        yield put({ type: FETCH_BUNDLE_INVOICE_FAILURE });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
